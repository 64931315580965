import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";

import GenerateNewTermSheetIcon from "../../assets/Generate_term_sheet.svg";
import PreviewIcon from "../../assets/preview.svg"

import DownloadIcon from "../../assets/Shape.svg"
import SyncIcon from "../../assets/Group 37311.svg"
import EditIcon from "../../assets/EditIcon.svg"
import CloseIcon from "../../assets/Close-Icon-2.svg"
import { Timeline, TimelineConnector, TimelineContent, TimelineItem, timelineItemClasses, TimelineSeparator } from "@mui/lab";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { TextField } from "@mui/material";
import { LoansContext } from "../../context/LoansContext";

export default function TermSheetMenu(props: any) {
  const loanContextData = useContext(LoansContext);
  const { t } = useTranslation();
  const { 
    setTermSheetAction,
    termSheetVersionsData,
    setCurrentTermSheetVersion,
    isEmailSentOfCurrentVersion,
    currentTermSheetVersion,
    termSheetAction,
    renameTermSheetHandler
  } = props;

  const { priceExceptionStatus } = loanContextData ?? {};

  const [name, setName] = useState<string>("")
  const [isPriceExceptionRequested, setIsPriceExceptionRequested] = useState<boolean>(false)

  useEffect(() => {
    if(priceExceptionStatus && priceExceptionStatus === 'Requested') {
      setIsPriceExceptionRequested(true)
    }
  },[priceExceptionStatus])

  const handleKeyDown = (event: any, version: any) => {
    if (event.key === 'Enter') {
      setTermSheetAction("")
      renameTermSheetHandler(name, version)
      setName("")
    }
  };

  return (
    <Menu
      id="term-sheet-menu"
      anchorEl={props?.termSheetAnchorEl}
      open={props?.openTermSheetMenu}
      onClose={props?.handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ zIndex: 99999 }}
    >
      <Grid container sx={{ padding: "16px 16px 16px 16px", width: "256px", fontSize: "0.9rem" }}>
        <Grid item xs={12}>
          <Grid
            container
            onClick={() => {
              if(!isPriceExceptionRequested)
                setTermSheetAction("generate");
            }}
            style={{ cursor: isPriceExceptionRequested ? 'not-allowed' : "pointer", marginBottom: "12px" }}
          >
            <Grid item xs={1}>
              <img src={GenerateNewTermSheetIcon} title="Generate New Term Sheet"/>
            </Grid>
            <Grid item xs={11}>
              <span style={{ color: "#362866", paddingLeft: "8px" }}>
                {t("generateNewTermSheet")}
              </span>
            </Grid>
          </Grid>
        </Grid>
        {termSheetVersionsData?.length ? <>
          <Grid xs={12}><Divider /></Grid>
          <Grid container direction="column" xs={12} sx={{ marginTop: "8px", fontSize: "0.9rem" }}>
            <span>{t("latestTermSheets")}</span>
            <Timeline 
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              padding: "6px 4px"
            }}
            >
              <TimelineItem sx={{minHeight: "32px"}}>
                <TimelineSeparator>
                  <TimelineConnector sx={{ height: 10 }}/>
                </TimelineSeparator>
                <TimelineContent sx={{padding: "0px"}}>
                  <KeyboardArrowUpIcon sx={{position:"absolute", top: "-10px", left: "-11px", color: "#bdbdbd"}}/>
                  <span style={{color:"#362866", position:"absolute", bottom: "-8px", paddingLeft:"20px", fontSize: "0.75rem"}}>Latest</span>
                </TimelineContent>
              </TimelineItem>
              {termSheetVersionsData?.map((item: any, index: number) => {
                return (
                  <TimelineItem sx={{minHeight: "32px"}}>
                    <TimelineSeparator>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{padding: "0px", fontSize: "0.9rem"}}>
                      {termSheetAction === 'rename' && currentTermSheetVersion === item.termSheetVersion ?
                        <Grid container xs={12} sx={{ marginTop: "8px" }}>
                          <Grid item xs={11} sx={{paddingLeft:"20px"}}>
                            <HorizontalRuleIcon sx={{position:"absolute", top: "8px", left: "-4px", color: "#bdbdbd"}}/>
                            <TextField
                              size='small'
                              onChange={(e) => setName(e.target.value)} 
                              onKeyDown={(e) => handleKeyDown(e,item.termSheetVersion)}
                              fullWidth
                              value={name}InputProps={{
                                style: {
                                  height: '24px', // Adjust the height as needed
                                  padding: '0 4px', // Adjust padding to make it more compact
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={1} style={{ 
                            cursor: "pointer",
                            paddingLeft:"8px",
                            paddingTop: "2px"
                            }} 
                          >
                        
                            <img
                              src={CloseIcon}
                              title="Cancel"
                              onClick={() => {
                              setTermSheetAction("");
                              setCurrentTermSheetVersion("")
                             }}
                            />  
                          </Grid>

                    </Grid> :
                    <Grid container xs={12} sx={{ marginTop: "8px" }}>
                    <Grid item xs={8} sx={{paddingLeft:"20px"}} onDoubleClick={() => {
                      setTermSheetAction("rename");
                      setName(item?.termSheetName ? item?.termSheetName : `Termsheet v${item?.termSheetVersion}`)
                      setCurrentTermSheetVersion(item.termSheetVersion)
                    }}>
                      <HorizontalRuleIcon sx={{position:"absolute", top: "8px", left: "-4px", color: "#bdbdbd"}}/>
                      {item.termSheetName ? <span>{item.termSheetName }</span> : <>
                        <span>{t("termSheetV").slice(0, -1)}</span>
                        <span style={{ color: "#362866" }}>v{item?.termSheetVersion}</span>
                      </>}
                      
                    </Grid>
                    <Grid item xs={4} style={{ 
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      height: "fit-content"
                     }} >
                      
                      <img
                        src={PreviewIcon}
                        title="View"
                        onClick={() => {
                          setTermSheetAction("view");
                          setCurrentTermSheetVersion(item.termSheetVersion)
                        }}
                      />  

                      {index === 0 ? <>
                        <img src={DownloadIcon}
                          title="Download"
                          onClick={() => {
                            setCurrentTermSheetVersion(item.termSheetVersion)
                            setTermSheetAction("download")
                          }}
                        />  

                        {isEmailSentOfCurrentVersion ? 
                        <img src={SyncIcon}
                          title="Generate again"
                          style={{cursor: isPriceExceptionRequested ? 'not-allowed' : 'pointer'}}
                          onClick={() => {
                            if(!isPriceExceptionRequested){
                              setCurrentTermSheetVersion(item.termSheetVersion)
                              setTermSheetAction("regenerate")
                            }
                          }} /> 
                          : 
                          <img src={EditIcon}
                            title="Edit"
                            onClick={() => {
                              setCurrentTermSheetVersion(item.termSheetVersion)
                              setTermSheetAction("edit")
                              }} /> 
                          }
                      </> : null}
                    </Grid>

                  </Grid>
                      }
                    </TimelineContent>
                  </TimelineItem>
                )
              })}
            </Timeline>
          </Grid>
        </> : null}
      </Grid>
    </Menu>
  )
}
