import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  createRequestWaiver,
  fetchRequestWaiver,
} from "../../../../../../services/network";

import { LoansContext } from "../../../../../../context/LoansContext";
import { RootContext } from "../../../../../../context/RootContext";

import RequestWaiver from "./RequestWaivers";

import "./RequestWaiverWrapper.css";
import {
  formatCurrency,
  validateNumberField,
} from "../../../../../../utils/v2/common";
import { sanitizeCurrency } from "./../../../../../../utils/Common";
import { updateRequestWaiver } from "./../../../../../../services/network";
import { SizingTypeValue } from "../../../../../../types/enums";

export default function RequestWaiverWrapper(props: any) {
  const { handleSaveApplication } = props;
  const params = useParams();
  const {
    buyDownOptions,
    currentSelectedInterestRate,
    setCurrentSelectedInterestRate,
    leverageAndPricingData,
    setLeverageAndPricingData,
    detailedLoanSizingData,
    loanDetails,
    quickLoanSizingData,
    setChipsData,
    chipsData,
  } = useContext(LoansContext);
  const { decodedTokenData } = useContext(RootContext);

  const [isRequestPricingWaiver, setIsRequestPricingWaiver] = useState(false);
  const [requestedWaivers, setRequestedWaivers] = useState([]);
  const [waiverHistory, setWaiverHistory] = useState([]);

  const [comments, setComments] = useState("");
  const [document, setDocument] = useState<any>(null);
  const [documentPath, setDocumentPath] = useState("");
  const [userAction, setUserAction] = useState("");
  const [updatedDiscountPoints, setUpdatedDiscountPoints] = useState<any>({});
  const [approvalComment, setApprovalComment] = useState("");
  const [rejectionComment, setRejectionComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<any>({});

  const handleWaiverRequest = async (filePath: string, fileName: string) => {
    setIsLoading(true);
    try {
      // if (!document) return;
      const selectedBuyDownOption =
        buyDownOptions?.find(
          (option: any) =>
            option.interestRate ===
            Number(
              +currentSelectedInterestRate.interestRate?.replace("%", "") / 100
            )
        ) ?? {};

      const discountPoints =
        (updatedDiscountPoints?.discountPoints ??
          selectedBuyDownOption?.discountPointPercentage) ||
        currentSelectedInterestRate?.discountPoints;

      const discountPointsAmount =
        (updatedDiscountPoints?.discountPointsWithDollar?.replace(
          /[^0-9.]/g,
          ""
        ) ??
          selectedBuyDownOption?.discountPointAmount) ||
        currentSelectedInterestRate?.discountPointsWithDollar?.replace(
          /[^0-9.]/g,
          ""
        );

      const reqBody = {
        loanId: params?.loanId,
        waiverType: "Discount Waiver",
        data: {
          interestRate: currentSelectedInterestRate?.interestRate,
          requestedData: {
            discountPoints: discountPoints
              ? `${parseFloat(discountPoints)?.toFixed(4)}`
              : discountPoints,
            discountPointsAmount: discountPointsAmount
              ? `${parseFloat(discountPointsAmount)?.toFixed(2)}`
              : discountPointsAmount,
            interestRate:
              updatedDiscountPoints?.interestRate ||
              currentSelectedInterestRate?.interestRate,
            comments,
            userName: decodedTokenData?.name,
            createdOn: new Date(),
          },
          actualData: {
            discountPoints: selectedBuyDownOption?.discountPointPercentage ? `${parseFloat(selectedBuyDownOption?.discountPointPercentage)?.toFixed(4)}` : selectedBuyDownOption?.discountPointPercentage,
            discountPointsAmount: selectedBuyDownOption?.discountPointAmount ? `${parseFloat(selectedBuyDownOption?.discountPointAmount)?.toFixed(2)}` : selectedBuyDownOption?.discountPointAmount,
            interestRate: currentSelectedInterestRate?.interestRate,
          },
          isActive: true,
          waiverStatus: "Requested",
          fileLocation: filePath,
          fileName: fileName,
        },
      };
      await createRequestWaiver(reqBody);
      const tempChipsData = chipsData?.filter(
        (chip: string) => !chip.includes("Price Exception")
      );
      setChipsData([...tempChipsData, "Price Exception: Requested"]);
      fetchWaivers();
      setDocument(null);
      setComments("");
      setIsRequestPricingWaiver(false);
      setUpdatedDiscountPoints({});
      handleSaveApplication(false, "", "waiverRequested");
    } catch (err) {
      console.error(
        "Something went wrong while requesting waiver. ERROR:",
        err
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserAction = (action: string) => {
    setUserAction(action);
  };

  const fetchWaivers = async () => {
    setIsLoading(true);
    try {
      const reqBody = [params.loanId];
      const { data } = await fetchRequestWaiver(reqBody);

      const activeWaiver = data?.data
        ?.map((item: any, index: number) => ({ ...item, originalIndex: index }))
        ?.filter((item: any) => item?.data?.isActive);

      const sortedData = data?.data
        ?.map((item: any, index: number) => ({ ...item, originalIndex: index }))
        ?.filter((item: any) => !item?.data?.isActive)
        ?.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      setRequestedWaivers(activeWaiver);
      setWaiverHistory(sortedData);
    } catch (err) {
      console.error("Something went wrong while fetching waivers. ERROR:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDiscountPointChange = (discountValue: any) => {
    const { value, type, key } = discountValue;
    if (value) {
      setUpdatedDiscountPoints({
        ...currentSelectedInterestRate,
        [key]: value,
      });
      setCurrentSelectedInterestRate({
        ...currentSelectedInterestRate,
        [key]: value,
      });
    } else {
      const returnObj = {
        ...currentSelectedInterestRate,
        discountPoints: "",
        discountPointsWithDollar: "",
      };
      setCurrentSelectedInterestRate({
        ...returnObj,
      });
      setUpdatedDiscountPoints({
        ...currentSelectedInterestRate,
        returnObj,
      });
    }
  };

  const validateInput = (value: any, key: any) => {
    let isError = false;
    const sanitizedValue = value.replace(/[\s,$%]/g, "");
    if (
      !sanitizedValue ||
      +sanitizedValue < 0 ||
      validateNumberField(sanitizedValue)
    ) {
      setFieldErrors({
        ...fieldErrors,
        [key]: "pleaseEnterValidValue",
      });
      isError = true;
    }
    return isError;
  };

  const handleDiscountBlur = (discountValue: any) => {
    const { value, type, key } = discountValue;

    const isError = validateInput(value, key);
    if (isError) {
      const returnObj = {
        ...currentSelectedInterestRate,
        discountPoints: "",
        discountPointsWithDollar: "",
      };
      setUpdatedDiscountPoints({
        ...returnObj,
      });
      setCurrentSelectedInterestRate({
        ...returnObj,
      });
      return;
    }

    const data =
      loanDetails?.sizingType === SizingTypeValue?.QUICK_LOAN_SIZING
        ? quickLoanSizingData?.loanInformation?.borrowerRequested
        : detailedLoanSizingData?.loanEconomics?.borrowerRequested;
    const tempValue = value?.replace(/[$,]/g, "");
    if (tempValue) {
      const newValue: any =
        type === "percentage"
          ? `${formatCurrency(
              (tempValue?.replace(/[$,]/g, "") * +data) / 100,
              2
            )}`
          : (+tempValue?.replace(/[$,]/g, "") / +data) * 100;

      const returnObj = {
        ...currentSelectedInterestRate,
        discountPoints:
          key === "discountPoints"
            ? `${parseFloat(tempValue)?.toFixed(4)}`
            : newValue?.toFixed(4),
        discountPointsWithDollar:
          key === "discountPointsWithDollar"
            ? tempValue?.includes("$")
              ? tempValue
              : formatCurrency(tempValue, 2)
            : newValue,
      };
      setUpdatedDiscountPoints({
        ...returnObj,
      });
      setCurrentSelectedInterestRate({
        ...returnObj,
      });
    } else {
      const returnObj = {
        ...currentSelectedInterestRate,
        discountPoints: "",
        discountPointsWithDollar: "",
      };
      setUpdatedDiscountPoints({
        ...returnObj,
      });
      setCurrentSelectedInterestRate({
        ...currentSelectedInterestRate,
      });
    }
    setFieldErrors({});
  };

  const handleApprovalComment = (e: any) => {
    setApprovalComment(e.target.value);
  };

  const handleRejectionComment = (e: any) => {
    setRejectionComment(e.target.value);
  };

  const handleApproveWaiver = async (props: any) => {
    const { _id } = props;

    setIsLoading(true);
    try {
      const reqBody = {
        loanId: params?.loanId,
        waiverId: _id,
        data: {
          finalData: {
            discountPoints:
              updatedDiscountPoints?.discountPoints ||
              props?.data?.requestedData?.discountPoints ||
              currentSelectedInterestRate?.discountPoints,
            discountPointsAmount:
              updatedDiscountPoints?.discountPointsWithDollar ||
              props?.data?.requestedData?.discountPointsAmount ||
              currentSelectedInterestRate?.discountPointsWithDollar,
            interestRate:
              updatedDiscountPoints?.interestRate ||
              props?.data?.requestedData?.interestRate ||
              currentSelectedInterestRate?.interestRate,
            comments: approvalComment,
            userName: decodedTokenData?.name,
            createdOn: new Date(),
          },
          waiverStatus: "Approved",
        },
        waiverType: "Discount Waiver",
      };
      await updateRequestWaiver(reqBody);
      const tempChipsData = chipsData?.filter(
        (chip: string) => !chip.includes("Price Exception")
      );
      setChipsData([...tempChipsData, `Price Exception: Approved`]);
      fetchWaivers();
      handleSaveApplication(false, "", "waiverApproved");
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      setRejectionComment("");
    }
  };

  const handleRejectionWaiver = async (props: any) => {
    const { _id } = props;

    if (!rejectionComment) {
      // alert("Please enter rejection comment.");
      return;
    }

    setIsLoading(true);
    try {
      const reqBody = {
        loanId: params?.loanId,
        waiverId: _id,
        data: {
          finalData: {
            discountPoints:
              props?.data?.requestedData?.discountPoints ||
              currentSelectedInterestRate?.discountPoints,
            discountPointsAmount:
              props?.data?.requestedData?.discountPointsAmount ||
              currentSelectedInterestRate?.discountPointsWithDollar,
            interestRate:
              props?.data?.requestedData?.interestRate ||
              currentSelectedInterestRate?.interestRate,
            comments: rejectionComment,
            userName: decodedTokenData?.name,
            createdOn: new Date(),
          },
          waiverStatus: "Rejected",
        },
        waiverType: "Discount Waiver",
      };
      await updateRequestWaiver(reqBody);
      const tempChipsData = chipsData?.filter(
        (chip: string) => !chip.includes("Price Exception")
      );
      setChipsData([...tempChipsData, `Price Exception: Rejected`]);
      fetchWaivers();
      handleSaveApplication(false, "", "waiverRejected");
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelApproveWaiver = () => {
    const findCurrentSelection = buyDownOptions?.find(
      (option: any) =>
        option.interestRate ===
        Number(
          currentSelectedInterestRate?.interestRate?.replace("%", "") / 100
        )
    );

    setCurrentSelectedInterestRate({
      interestRate: `${(findCurrentSelection?.interestRate * 100)?.toFixed(
        3
      )}%`,
      discountPoints: `${findCurrentSelection?.discountPointPercentage?.toFixed(
        4
      )}`,
      discountPointsWithDollar: formatCurrency(
        findCurrentSelection?.discountPointAmount,
        2
      ),
    });

    setIsLoading(true);
    setUserAction("");
    setApprovalComment("");
    setRejectionComment("");
    setIsLoading(false);
    setUpdatedDiscountPoints({});
  };

  useEffect(() => {
    fetchWaivers();
  }, []);

  useEffect(() => {
    setFieldErrors({});
  }, [currentSelectedInterestRate?.interestRate]);

  return (
    <div className="ww-100" style={{marginTop:"10px"}}>
      <RequestWaiver
        currentSelectedInterestRate={currentSelectedInterestRate}
        comments={comments}
        setComments={setComments}
        requestedWaivers={requestedWaivers}
        isRequestPricingWaiver={isRequestPricingWaiver}
        setRequestedWaivers={setRequestedWaivers}
        setIsRequestPricingWaiver={setIsRequestPricingWaiver}
        handleWaiverRequest={handleWaiverRequest}
        document={document}
        setDocument={setDocument}
        documentPath={documentPath}
        setDocumentPath={setDocumentPath}
        userAction={userAction}
        handleUserAction={handleUserAction}
        handleDiscountPointChange={handleDiscountPointChange}
        updatedDiscountPoints={updatedDiscountPoints}
        handleDiscountBlur={handleDiscountBlur}
        handleApprovalComment={handleApprovalComment}
        approvalComment={approvalComment}
        handleApproveWaiver={handleApproveWaiver}
        cancelApproveWaiver={cancelApproveWaiver}
        rejectionComment={rejectionComment}
        handleRejectionComment={handleRejectionComment}
        handleRejectionWaiver={handleRejectionWaiver}
        fetchWaivers={fetchWaivers}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        waiverHistory={waiverHistory}
        fieldErrors={fieldErrors}
        buyDownOptions={buyDownOptions}
        leverageAndPricingData={leverageAndPricingData}
      />
    </div>
  );
}
