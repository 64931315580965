import React, { useState, useContext, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";

import Header from "../../components/Header";

import { validateForm } from "../../utils/Validations";

import { EvaluateLoanFormType } from "../../types/evaluateLoanType";
import { ObjectType } from "../../types/tableTypes";
import { Clients } from "../../types/enums";

import { RootContext } from "../../context/RootContext";

import { clickContinue } from "../../services/network";

import { productTypeConfig } from "./evaluateConstant";
import { ELButtonGroup } from "./ELButtonGroup";

import "./evaluateLoan.css";
import { getCookie } from "../../utils/Common";

const useButtonStyles = makeStyles(() => ({
  root: {
    borderRadius: "6px",
    marginTop: "32px !important;",
    fontSize: "16px",
    padding: "12px",
    color: "white",
  },
}));

const initialDscrMMTCFormData = {
  productType: "",
  propertyType: "",
  sizingType: "",
};

const initialDscrFormData = {
  productType: "",
  propertyType: "",
};

const initialBridgeFormData = {
  productType: "",
  loanType: "",
  propertyType: "",
};

const headerConfig: any = {
  showEvaluatePageBtn: true
}
export default function EvaluateLoan() {
  const [formData, setFormData] = useState<EvaluateLoanFormType>(initialDscrFormData);
  const [selectedSubFields, setSelectedSubFields] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    setEvaluateLoanForm,
    userPermissions,
    dynamicConfig,
    setCurrentPath,
  } = useContext(RootContext);
  const navigate = useNavigate();

  const classes = useButtonStyles();
  const { t } = useTranslation();

  const productOptions: ObjectType[] = useMemo(() => {
  
    const data = productTypeConfig(userPermissions)?.filter((config: any) =>
      config.isVisible?.({ showSizingType: dynamicConfig?.showSizingType, realm: dynamicConfig?.ViteAppKcRealm })
    );
    return data;
  }, [userPermissions, dynamicConfig.ViteAppKcRealm]);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
    if (!formData?.productType) return;
    const tempFormData: any = { ...formData };
    const selectedProductData = productOptions.find(
      (it) => it.key === formData?.productType
    );
    const keys: string[] = selectedProductData?.subFields?.map(
      (field: any) => field?.key
    );
    keys?.forEach((objKey: string) => {
      tempFormData[objKey] = "";
    });
    let subFields = selectedProductData?.subFields;
    if (formData?.productType === "investorDSCR") {
      delete tempFormData.loanType;
    }
    if (
      formData?.productType === "bridgeLoan" &&
      formData?.loanType === "groundUpText"
    ) {
      subFields = subFields.map((field: any) => {
        if (field.key === "propertyType") {
          return {
            ...field,
            fields: field.fields.map((subField: any) => {
              // if (subField.key === "multiFamilyMixedUseText") {
              //   return {
              //     ...subField,
              //     disable: true,
              //   };
              // }
              return subField;
            }),
          };
        }
        return field;
      });
    }
    setSelectedSubFields(subFields);
  }, [productOptions, formData]);

  function handleChange(value: any, key: string) {
    let tempFormData: any = { ...formData };
    if (key === "productType") {
      switch (value) {
        case "bridgeLoan":
          tempFormData = initialBridgeFormData;
          break;
        case "investorDSCR":
          tempFormData =
            dynamicConfig?.ViteAppKcRealm === Clients.BPL_SIZER
              ? JSON.stringify(tempFormData) !== JSON.stringify(initialDscrFormData) ? initialDscrFormData : { ...formData }
              : initialDscrMMTCFormData;
          break;
        default:
          break;
      }
    } else {
      tempFormData = { ...formData };
    }
    tempFormData[key] = value;
    setFormData(tempFormData);
  }

  async function handleContinue(e: React.FormEvent) {
    setIsLoading(true);
    try {
      e.preventDefault();
      if (validateForm(formData)) {
        return;
      }
      const reqBody: ObjectType = {};
      Object.keys(formData).forEach((key: string) => {
        const temp: ObjectType = { ...formData };
        reqBody[key] = t(temp[key]);
      });
      reqBody["orgId"] = getCookie("orgId");
      const { data } = await clickContinue(reqBody);

      sessionStorage.setItem(
        "evaluateLoanForm",
        JSON.stringify({ ...reqBody })
      );
      setEvaluateLoanForm(reqBody);

      let redirectUrl = `/loan/${reqBody.productType.replace(/ +/g, "")}`;
      if (reqBody?.loanType) {
        redirectUrl = `${redirectUrl}/${reqBody?.loanType?.replace(/ +/g, "")}`;
      }

      const loanId = data?.data?.loanId;
      navigate(`${redirectUrl}/${loanId}`);
    } catch (err) {
      console.error(err, "API FAIL");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Header showEvaluatePageBtn={true} data-testid="evaluate-header" />
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <h2 className="el-header" data-testid="evaluate-loan">
            {t("evaluateLoanHeader")}
          </h2>
          <ELButtonGroup
            list={productOptions}
            groupLabel={"Product Type"}
            onClick={(e: any) => handleChange(e, "productType")}
            selectedValue={formData?.productType}
          />
          {formData?.productType !== "" && selectedSubFields?.length && (
            <>
              {selectedSubFields.map((subFieldData: ObjectType) => {
                return (
                  <ELButtonGroup
                    key={subFieldData.title}
                    list={subFieldData.fields}
                    groupLabel={subFieldData.title}
                    onClick={(e: any) => handleChange(e, subFieldData.key)}
                    selectedValue={
                      formData?.[
                        subFieldData?.key as keyof EvaluateLoanFormType
                      ]
                    }
                  />
                );
              })}
            </>
          )}
          <LoadingButton
            disabled={validateForm(formData)}
            fullWidth
            variant="contained"
            className={classes.root}
            type="submit"
            loading={isLoading}
            onClick={handleContinue}
            data-testid="continue-button"
          >
            {t("continue")}
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
}
