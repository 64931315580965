/* eslint-disable no-case-declarations */
import Cookies from "js-cookie";
import moment from "moment";

import { LeaseStatusEnum } from "../pages/Loans/v1/LoanFieldsData";

import { ObjectType } from "../types/tableTypes";

import { isFalsyButNotZero } from "./Validations";

// import { axiosClient } from "./utility";
import {
  finalPricingTables,
  labelFieldAdjustmentMapping,
  labelFieldBridgePriceMapping,
  labelFieldPricingMapping,
} from "./mappings";
import { postDownloadCondo } from "../services/network";
import { SizingTypeValue } from "../types/enums";
import { prePayTypeToMonthsMap } from "./v2/common";

export const filterLoanTypes: any = {
  "Bridge Loan": { label: "Bridge", key: "bridgeLoan" },
  "Investor DSCR": { label: "DSCR", key: "investorDSCR" },
};

export declare enum LoanStages {
  pre = "PRE",
  post = "POST",
  fes = "FES",
  ps = "PS",
  tv = "TOORVAL",
}

const LoanStage = {
  pre: "PRE",
  post: "POST",
  fes: "FES",
  ps: "PS",
  tv: "TOORVAL",
};
export enum LoanSizerEnum {
  oneFourFamily = "1-4 Family",
  multifamilyMixedUse = "Multifamily/MixedUse",
  groundUp = "Ground-Up",
}

export const rehabTypes = ["Fix & Flip", "Stabilized", "Rehab", "Fix&Flip"];

export const isString = (value: any) => {
  return typeof value === "string" || value instanceof String;
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const sanitizeCurrency = (text: string) => {
  try {
    const result = text?.replace(/\.$/, "");
    return currencyFormatter.format(
      Number.parseFloat(result?.replace(/[^0-9.]/g, ""))
    );
  } catch (e) {
    return text;
  }
};

export function isILP() {
  return window.location.pathname.startsWith("/internal/loan");
}

export const filterKeyOrgMap: { [key: string]: string } = {
  "loan.loanType.raw": "loanTypeName",
  "loan.loanState.raw": "status",
  "loan.loanDetailId.tradeId.raw": "toorakTradeID",
  "loan.loanStage.raw": "loanStage",
  "properties.propertyinfo.propertyType.raw": "propertyType",
  "loan.loanInfo.loanPurpose.raw": "loanPurpose",
  "originator.accountName.raw": "originator",
  "loan.loanInfo.recourseString.raw": "recourseString",
  "loan.loanInfo.crossCollaterlization": "crossCollaterlization",
  "loan.loanEconomics.accrualType.raw": "accrualType",
  "loan.loanEconomics.interestRateAsOfCutOffDate": "interestRate",
  "loan.loanEconomics.totalBudgetAmount.raw": "totalBudgetAmount",
  "loan.loanDetailId.loanId.raw": "toorakLoanId",
  "loan.loanInfo.primaryLoanId.raw": "originalLoanId",
};

export const filterKeyLatMap: { [key: string]: string } = {
  "loan.loanInfo.takeoutPartner.raw": "takeoutPartner",
  "loan.loanType.raw": "loanTypeName",
  "loan.loanState.raw": "status",
  "loan.loanStage.raw": "loanStage",
  "properties.propertyinfo.propertyType.raw": "propertyType",
  "loan.loanInfo.loanPurpose.raw": "loanPurpose",
  "originator.accountName.raw": "originator",
  "loan.loanInfo.recourseString.raw": "recourseString",
  "loan.loanInfo.crossCollaterlization": "crossCollaterlization",
  "loan.loanEconomics.accrualType.raw": "accrualType",
  "loan.loanDetailId.loanId.raw": "toorakLoanId",
  activeInteractions: "activeInteractions",
  "loan.loanInfo.primaryLoanId.raw": "originalLoanId",
  "loan.ddReportStatus.raw": "ddReportStatus",
  "loan.loanDetailId.loanSummaryId.teamIndiaTapetoFile.raw":
    "teamIndiaTapetoFile",
  "loan.loanDetailId.loanConfigId.tapeToFile.raw": "tapeToFile",
  "result.loanResult.riskScores.totalAggregateRisk": "riskScore",
  "result.loanResult.riskScores.riskBucket.raw": "riskBucket",
  "result.loanResult.loanCharacterisations.needEnhancedReview.raw":
    "needEnhancedReview",
};

export const filterKeyILPMap: { [key: string]: string } = {
  "originator.accountName.raw": "originator",
  "properties.propertyinfo.propertyType.raw": "propertyType",
  "loan.loanInfo.loanPurpose.raw": "loanPurpose",
  "loan.loanProcessType.raw": "loanProcessType",
  "loan.loanType.raw": "loanTypeName",
  "loan.roleOfAssignee.raw": "roleOfAssignee",
  "loan.assignedTo.raw": "assignedTo",
  "loan.workflowState.raw": "workflowState",
  "loan.tapeToFileStatus.raw": "tapeToFileStatus",
  "loan.loanInfo.toorakProduct.raw": "toorakProduct",
  "loan.ddReportStatus.raw": "ddReportStatus",
  "tasks.reconcile.taskStatus.raw": "task_status_buckets",
  "tasks.tag.taskStatus.raw": "task_status_buckets",
  "tasks.split.taskStatus.raw": "task_status_buckets",
  "tasks.review.taskStatus.raw": "task_status_buckets",
  "tasks.reconcile.assigneePartyId.raw": "assignee_party_id_buckets",
  "tasks.tag.assigneePartyId.raw": "assignee_party_id_buckets",
  "tasks.review.assigneePartyId.raw": "assignee_party_id_buckets",
  "loan.loanDetailId.loanSummaryId.teamIndiaTapetoFile.raw":
    "teamIndiaTapetoFile",
  "loan.loanDetailId.loanConfigId.tapeToFile.raw": "tapeToFile",
  "result.loanResult.riskScores.totalAggregateRisk": "riskScore",
  "result.loanResult.riskScores.riskBucket.raw": "riskBucket",
  "result.loanResult.loanCharacterisations.needEnhancedReview.raw":
    "needEnhancedReview",
};

export const filterKeyApraisalMap: { [key: string]: string } = {
  "appraisal.appraisal.loanId.raw": "loanId",
  "loan.loanState.raw": "status",
  "loan.loanDetailId.toorakLoanId.raw": "toorakLoanId",
  "appraisal.appraisal.createdBy.raw": "createdBy",
  "appraisal.appraisal.updatedBy.raw": "updatedBy",
  "appraisal.appraisal.submittedBy.raw": "submittedBy",
  "appraisal.appraisal.reviewedBy.raw": "reviewedBy",
  "originator.accountName.raw": "originator",
  "properties.propertyinfo.valuationSource.raw": "valuationSource",
};

const adjustmentKeysToBeExcluded = ["totalMarketDataDeductions"];

export function sanitizeData(
  x: string | number,
  isCurrency?: boolean,
  fixNumber?: number
) {
  const toFixNumber = fixNumber ?? 2;
  if (!isNaN(+x)) {
    const returnValue = Number(x)?.toFixed(toFixNumber).split(".");
    if (x && isCurrency) {
      return `$ ${returnValue[0]
        ?.toLocaleString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${returnValue[1]}`;
    }
    return x;
  }
  return x;
}

export function getValue(value1: any, value2: any) {
  const returnValue = value1 || value2;
  return returnValue ?? null;
}

export function getFormattedDate(date: any) {
  return date
    ? new Date(date)
        ?.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        ?.split("/")
        ?.reverse()
        ?.join("-")
    : null;
}

function hasOnlySpaces(input: string) {
  return /^\s*$/.test(input);
}

function getCostBasisValue(
  propertyEconomics: ObjectType,
  fetchData: ObjectType
) {
  let value: string | number = "";
  if (fetchData?.propertyType === "Multifamily/MixedUse") {
    value =
      Number(propertyEconomics?.borrowerPaidCostToDate) +
      Number(propertyEconomics?.purchasePrice);
  } else if (rehabTypes.includes(fetchData?.loanType)) {
    value =
      ((Number(propertyEconomics?.closingCosts?.replace("%", "")) + 100) *
        Number(propertyEconomics?.purchasePrice)) /
      100;
  }
  if (!isNaN(value as number)) {
    return value;
  }
  return "";
}

export const normalizeReqBody = (
  body: ObjectType,
  propertyDetails: ObjectType[],
  type: string,
  loanId: string | any,
  defaultSelectedDataView: string,
  chipsData: string[],
  propType?: string,
  partyDetails?: ObjectType,
  fetchData?: ObjectType
) => {
  let reqBody: any = {};

  try {
    const properties: ObjectType[] = [];

    let foreignNational = body?.borrowerInformation?.immigrationStatus;

    if (body?.borrowerInformation?.borrowerGuarantor === "Yes") {
      if (
        body?.borrowerInformation?.immigrationStatus.includes("Foriegn") ||
        body?.borrowerInformation?.immigrationStatus === ""
      ) {
        foreignNational = "Yes";
      }
    } else if (body?.borrowerInformation?.borrowerGuarantor === "No") {
      foreignNational = "No";
    }

    let totalOriginationAndDiscountPoints: any = null;
    let rehabAmountBrl: any = null;
    let experience =
      body?.borrowerInformation?.experience ||
      body?.borrowerInformation?.propertiesCompletedWithinLast36Months ||
      body?.borrowerInformation?.borrowerGUCExperience;
    if (hasOnlySpaces(experience) || experience?.includes(".") || !experience) {
      experience = null;
    }

    const softCosts =
      body?.loanEconomics?.softCosts ||
      body?.loanEconomics?.softCostsCap15Rehab;

    switch (type) {
      case "BRIDGE":
        propertyDetails?.forEach((propDetail: ObjectType, propIdx: number) => {
          if (propDetail) {
            const { propertyEconomics, propertyInformation, propertyLocation } =
              propDetail;
            let closingCost = "0";
            if (propertyEconomics?.closingCosts) {
              closingCost = (
                +propertyEconomics?.closingCosts?.split("%")[0] / 100
              ).toFixed(4);
            }
            const costBasisValue = getCostBasisValue(
              propertyEconomics,
              fetchData as ObjectType
            );
            const currentProp = {
              propertyId: propIdx,
              propertyInfo: {
                isPropertyPurchasedLast2Years:
                  propertyInformation?.propertyPurchasedWithinLast2Years ??
                  null,
                numberOfUnits: propertyInformation?.numberOfUnits
                  ? hasOnlySpaces(propertyInformation?.numberOfUnits) ||
                    propertyInformation?.numberOfUnits?.includes(".")
                    ? null
                    : +propertyInformation?.numberOfUnits
                  : 1,
                propertyType: propertyInformation?.propertyType ?? "",
                changeInUseCase:
                  propertyInformation?.expansionOrChangeOfUseCase ?? "",
                valuationSource: propertyInformation?.valuationSource,
                isEntitledLand:
                  propertyInformation?.entitledAsOfRightDevelopment ?? null,
                appraisalReportedNotOrNcf:
                  propertyEconomics?.appraisalStabilizedNOI ?? null,
                percentOccupied: propertyInformation?.percentOccupied
                  ? propertyInformation?.percentOccupied / 100
                  : null,
              },
              propertyLocation: {
                city: propertyLocation?.city?.replace(",", ""),
                state: propertyLocation?.state?.replace(",", ""),
                postalCode: propertyLocation?.postalCode,
              },
              propertyEcon: {
                assignmentFees: +propertyEconomics?.assignmentFee,
                grossPotentialRent:
                  propertyEconomics?.estimatedRentPerYearUponCompletion?.toString(),
                costBasisAfterCostIncurred: costBasisValue,
                closingCost,
                originalAsIsAppraisalValue:
                  propertyEconomics?.originalAsIsAppraisalValue ||
                  propertyEconomics?.orgAppraisalValue ||
                  propertyEconomics?.asIsValue,
                originalAsRepairedAppraisedValue:
                  propertyEconomics?.asRepairedValueARV?.toString() ||
                  propertyEconomics?.afterRepairValue ||
                  "0",
                purchasePrice:
                  propertyEconomics?.purchasePrice?.toString() ?? null,
                grossPotentialRate:
                  propertyEconomics?.grossOriginationRate ||
                  propertyEconomics?.estimatedRentPerYearUponCompletion ||
                  "0",
                purchaseDate: getFormattedDate(propertyEconomics?.purchaseDate),
                grossCondoSelloutValue:
                  propertyEconomics?.grossCondoSelloutValue ?? null,
                borrowerPaidCosts:
                  propertyEconomics?.borrowerPaidCostToDate ?? null,
                annualPropertyTaxes:
                  propertyEconomics?.annualPropertyTaxes ?? null,
                insurance: propertyEconomics?.annualInsurance ?? null,
                marketRentPerMonth:
                  propertyEconomics?.grossPotentialRentMonthly ?? null,
                annualHOAFee: propertyEconomics?.annualHOAFee ?? null,
              },
            };
            properties.push(currentProp);
          }
        });

        if (
          isFalsyButNotZero(+body?.loanEconomics?.hardCosts) &&
          isFalsyButNotZero(+softCosts)
        ) {
          rehabAmountBrl = +body?.loanEconomics?.hardCosts + +softCosts;
        }

        if (body?.loanEconomics?.orgBrokerFee) {
          const orgBrokerFee = (
            +body?.loanEconomics?.orgBrokerFee.replace("%", "") / 100
          ).toFixed(6);
          totalOriginationAndDiscountPoints = orgBrokerFee;
        }

        if (body?.loanEconomics?.totalOrgAndDiscountPoints) {
          const orgBrokerFee = (
            +body?.loanEconomics?.totalOrgAndDiscountPoints.replace("%", "") /
            100
          ).toFixed(6);
          totalOriginationAndDiscountPoints = orgBrokerFee;
        }

        reqBody = {
          loanFact: {
            loan: {
              loanDetail: {
                originatorPartyId:
                  fetchData?.orgId || partyDetails?.orgId || "",
                fundingType:
                  fetchData?.fundingType || partyDetails?.orgFundingType || "",
              },
              loanEcon: {
                hardCost: isFalsyButNotZero(+body?.loanEconomics?.hardCosts)
                  ? +body?.loanEconomics?.hardCosts
                  : null,
                softCost: isFalsyButNotZero(softCosts) ? +softCosts : null,
                totalBudgetAmount:
                  (Number(body?.loanEconomics?.totalRehabBudget) ||
                    Number(body?.loanEconomics?.totalBudget) ||
                    rehabAmountBrl) ??
                  0,
                totalMaxLoanAmount:
                  Number(
                    body?.loanEconomics?.originalLoanAmount ||
                      body?.loanEconomics?.initialLoanAmount ||
                      0
                  ) + Number(body?.loanEconomics?.financedBudget ?? 0),
                originalLoanAmount:
                  body?.loanEconomics?.originalLoanAmount?.toString() ||
                  body?.loanEconomics?.initialLoanAmount?.toString() ||
                  null,
                totalOriginationAndDiscountPoints,
                interestReserve: body?.loanEconomics?.interestReserve || 0,
                rehabAmountBrl,
                financedBudgetAmount:
                  body?.loanEconomics?.financedBudget || null,
                interestRate: !isNaN(
                  +body?.loanEconomics?.interestRate ||
                    +body?.loanEconomics?.originalInterestRate
                )
                  ? (
                      (+body?.loanEconomics?.interestRate ||
                        +body?.loanEconomics?.originalInterestRate) / 100
                    ).toFixed(6)
                  : null,
                includeOutOfPocketBudgetInARLTV:
                  body?.loanEconomics?.includeOutOfPocketBudgetARLTV ?? true,
              },
              loanInfo: {
                loanStructure: body?.loanInformation?.loanStructure,
                product: body?.loanInformation?.product || "RENTAL",
                toorakProduct:
                  body?.loanInformation?.toorakProduct ||
                  propType === "GroundUp"
                    ? "Ground-Up"
                    : "Rehab",
                loanType:
                  body?.loanInformation?.loanType || type?.toUpperCase(),
                loanStage: body?.loanInformation?.loanStage || "FES",
                pledgeOfEquity:
                  body?.loanInformation?.pledgeOfEquity === "No" ? "N" : "Y",
                cashOutFlag: body?.loanInformation?.cashOutFlag,
                loanPurpose: body?.loanInformation?.loanPurpose ?? "",
                exitStrategy: body?.loanInformation?.exitStrategy,
                loanId,
                loanTerm:
                  hasOnlySpaces(body?.loanInformation?.loanTerm) ||
                  body?.loanInformation?.loanTerm?.includes(".")
                    ? null
                    : body?.loanInformation?.loanTerm ?? null,
                irFundingSource: body?.loanInformation?.irFundingSource ?? null,
                isPermitApprovalsAndFullPlansInPlace:
                  body?.loanInformation?.permitApprovalsAndFullPlans ?? null,
              },
              loanUserMap: [
                {
                  isPrimary: true,
                  loanUserSequence: "1",
                  loanUserType: "Borrower",
                  originalCreditScoreMedian:
                    body?.borrowerInformation?.qualifyingCreditScore ||
                    body?.borrowerInformation?.originalCreditScore ||
                    body?.borrowerInformation?.creditScoreActual,
                  experience,
                  inState: body?.borrowerInformation?.inState ?? null,
                  borrowerGUCExperience:
                    hasOnlySpaces(
                      body?.borrowerInformation?.borrowerGUCExperience
                    ) ||
                    body?.borrowerInformation?.borrowerGUCExperience?.includes(
                      "."
                    )
                      ? 0
                      : body?.borrowerInformation?.borrowerGUCExperience,
                  heavyRehabExperience:
                    hasOnlySpaces(
                      body?.borrowerInformation?.heavyRehabExperience
                    ) ||
                    body?.borrowerInformation?.heavyRehabExperience?.includes(
                      "."
                    )
                      ? 0
                      : body?.borrowerInformation?.heavyRehabExperience,
                  customer: {
                    partyType:
                      body?.borrowerInformation?.borrowerType === "Entity"
                        ? "account"
                        : "person",
                    foreignNationalString: foreignNational || null,
                  },
                },
              ],
            },
            properties,
          },
        };

        if (
          chipsData.includes("Multifamily/Mixed Use") &&
          propType !== "GroundUp"
        ) {
          reqBody.loanFact.loan.loanEcon.totalBudgetAmount =
            rehabAmountBrl || 0;
        }

        if (propType === "GroundUp") {
          const value =
            +body?.loanEconomics?.hardCosts +
            (+body?.loanEconomics?.softCosts ||
              +body?.loanEconomics?.softCostsCap15Rehab);
          reqBody.loanFact.loan.loanEcon.totalBudget = value;
          reqBody.loanFact.loan.loanEcon.totalBudgetAmount = value;
        }

        if (partyDetails?.orgBucketCategory) {
          // add if originator category bucket is present
          reqBody.loanFact.loan.loanDetail.originatorCategoryBucket =
            partyDetails?.orgBucketCategory;
        }
        break;
      case "30Year":
        propertyDetails.forEach((propDetail: ObjectType, propIdx: number) => {
          const units: ObjectType[] = [];
          const {
            propertyEconomics,
            propertyInformation,
            propertyLocation,
            unitInformation,
          } = propDetail;

          let closingCost = "0";
          if (propertyEconomics?.closingCosts) {
            closingCost = (
              +propertyEconomics?.closingCosts?.split("%")[0] / 100
            ).toFixed(4);
          }
          if (Array.isArray(unitInformation)) {
            unitInformation.forEach((unitInfo: ObjectType) => {
              const returnObj = {
                propertyUnitId: propIdx + 1,
                leaseStatus:
                  unitInfo?.rentalCharacterization?.toString() ?? null,
                marketRentMonthly: unitInfo?.monthlyMarketRent ?? null,
                currentLeaseTermMonths:
                  unitInfo?.currentLeaseTermsInMonths?.includes(".") ||
                  hasOnlySpaces(unitInfo?.currentLeaseTermsInMonths)
                    ? null
                    : +unitInfo?.currentLeaseTermsInMonths,
                inPlaceLeaseRentMonthly:
                  unitInfo?.inPlaceLeaseRentMonthly || "",
                // mostRecentLeaseRentMonthly:
                //   unitInfo?.mostRecentLeaseRentMonthly || null,
                unitOccupancy: unitInfo?.rentalCharacterization?.includes(
                  "Occupied"
                )
                  ? 1
                  : 0,
              };
              units.push(returnObj);
            });
          }
          const currentProp: ObjectType = {
            propertyId: propIdx + 1,
            propertySummary: {
              monthlyTaxAmount: null,
              monthlyInsuranceAmount: null,
              escrowTaxes: null,
              escrowInsurance: null,
            },
            propertyInfo: {
              numberOfUnits: propertyInformation?.numberOfUnits
                ? hasOnlySpaces(propertyInformation?.numberOfUnits) ||
                  propertyInformation?.numberOfUnits?.includes(".")
                  ? 0
                  : propertyInformation?.numberOfUnits
                  ? +propertyInformation?.numberOfUnits
                  : 1
                : 1,
              propertyType:
                (propertyLocation?.propertyType ||
                  propertyInformation?.propertyType) ??
                "",
              postRehabSquareFootage: 0,
              squareFootage:
                propertyInformation?.propertySqftFootage?.replaceAll(",", ""),
              preRehabSquareFootage:
                propertyInformation?.propertySqftFootage?.replaceAll(",", ""),
              propertyAcquisitionDate: getFormattedDate(
                propertyInformation?.originalDateOfAcquisition
              ),
              mostRecentPropertyValuationDate: getFormattedDate(
                propertyInformation?.recentPropValuationDate
              ),
              isPurchasedWithDebt: propertyInformation?.propertyWithDebt,
            },
            propertyLocation: {
              city: propertyLocation?.city,
              state: propertyLocation?.state,
              postalCode: propertyLocation?.postalCode,
            },
            propertyEcon: {
              costBasisAfterCostIncurred:
                (+closingCost + 1) * propertyEconomics?.purchasePrice,
              closingCost,
              additionalEligibleCost: null,
              originalAsIsAppraisalValue: propertyEconomics?.asIsValue || null,
              purchasePrice:
                propertyEconomics?.propertyAcquisitionPrice ||
                propertyEconomics?.purchasePrice ||
                null,
              thirdPartyValuationStatus:
                propertyEconomics?.thirdPartyValuationStatus ?? null,
              thirdPartyValuation: propertyEconomics?.thirdPartyPopertyValue,
              annualPropertyTaxes: propertyEconomics?.annualPropertyTaxes,
              annualHazardInsurance: propertyEconomics?.annualHazardInsurance,
              annualFloodInsurance: propertyEconomics?.annualFloodInsurance,
              annualHOAFee: propertyEconomics?.annualHOAFee,
              acquisitionPrice:
                propertyEconomics?.propertyAcquisitionPrice || null,
              decliningMarkets: propertyEconomics?.decliningMarkets ?? null,
            },
          };
          if (defaultSelectedDataView !== "minimumDataView") {
            currentProp.units = units;
          }
          properties.push(currentProp);
        });

        const borrowerProceeds =
          body?.loanInformation?.borrowerProceeds ||
          body?.loanInformation?.cashOutRefinance;

        reqBody = {
          loanFact: {
            loan: {
              loanDetail: {
                originatorPartyId:
                  body?.orgId || fetchData?.orgId || partyDetails?.orgId || "",
                // fundingType: partyDetails?.orgFundingType || "",
              },
              loanEcon: {
                rateType: body?.loanEconomics?.rateType ?? "Fixed",
                originalLoanAmount:
                  body?.loanEconomics?.originalLoanAmount?.toString() ||
                  body?.loanEconomics?.initialLoanAmount?.toString() ||
                  body?.loanEconomics?.borrowerRequested?.toString(),
                prePaymentPenaltyMonths:
                  body?.loanEconomics?.prepaymentPenaltyTypeMonths ?? null,
                prepayPenaltyType: body?.loanEconomics?.prepayPenaltyType,
                interestRate: +(
                  body?.loanEconomics?.originalInterestRate / 100
                ).toFixed(6),
                interestOnlyPeriod: body?.loanEconomics?.ioPeriod || 0,
              },
              loanInfo: {
                product: body?.loanInformation?.product || "RENTAL",
                toorakProduct: body?.loanInformation?.toorakProduct || "Rehab",
                loanType:
                  body?.loanInformation?.loanType || type?.toUpperCase(),
                loanStage: body?.loanInformation?.loanStage || "FES",
                loanId,
                borrowerProceeds:
                  hasOnlySpaces(borrowerProceeds) ||
                  borrowerProceeds?.includes(".")
                    ? null
                    : borrowerProceeds ?? null,
                predominantState:
                  body?.loanInformation?.predominantState ?? null, // else null
                predominantPropertyType:
                  body?.loanInformation?.predominantPropertyType === ""
                    ? null
                    : body?.loanInformation?.predominantPropertyType,
                originalMaturityDate: null,
                expectedClosingDate: getFormattedDate(
                  body?.loanInformation?.expectedClosingDate
                ),
                loanPurpose: body?.loanInformation?.loanPurpose ?? "",
                condoEligibility:
                  body?.loanInformation?.condoEligibility || "N/A",
                loanTerm:
                  hasOnlySpaces(body?.loanInformation?.loanTerm) ||
                  body?.loanInformation?.loanTerm?.includes(".")
                    ? null
                    : body?.loanInformation?.loanTerm ?? null,
                crossCollaterlization:
                  body?.loanInformation?.crossCollaterlization ?? "N",
                creditEvent: body?.loanInformation?.creditEvent ?? "No",
              },
              loanUserMap: [
                {
                  isPrimary: true,
                  loanUserSequence: "1",
                  loanUserType: "Borrower",
                  originalCreditScoreMedian:
                    body?.borrowerInformation?.qualifyingCreditScore ||
                    body?.borrowerInformation?.originalCreditScore ||
                    body?.borrowerInformation?.creditScoreActual,
                  experience,
                  borrowerGUCExperience:
                    hasOnlySpaces(
                      body?.borrowerInformation?.borrowerGUCExperience
                    ) ||
                    body?.borrowerInformation?.borrowerGUCExperience?.includes(
                      "."
                    )
                      ? 0
                      : body?.borrowerInformation?.borrowerGUCExperience,
                  heavyRehabExperience:
                    hasOnlySpaces(
                      body?.borrowerInformation?.heavyRehabExperience
                    ) ||
                    body?.borrowerInformation?.heavyRehabExperience?.includes(
                      "."
                    )
                      ? 0
                      : body?.borrowerInformation?.heavyRehabExperience,
                  customer: {
                    partyType:
                      body?.borrowerInformation?.borrowerType === "Entity"
                        ? "account"
                        : "person",
                    foreignNationalString: foreignNational || null,
                  },
                },
              ],
            },
            properties,
          },
        };

        const resultObj: any = {
          loanResult: {
            [loanId]: {},
          },
        };

        if (body?.loanEconomics?.dscr) {
          resultObj.loanResult[loanId] = {
            loanEconomics: {
              ...(resultObj.loanResult[loanId]?.loanEconomics ?? {}),
              dscr: body?.loanEconomics?.dscr ?? null,
            },
          };
        }

        if (defaultSelectedDataView === "minimumDataView") {
          const tempPropBody: ObjectType = {};
          if (propertyDetails[0]?.propertyInformation?.rentalCharacterization) {
            resultObj.loanResult[loanId] = {
              loanEconomics: {
                ...(resultObj.loanResult[loanId]?.loanEconomics ?? {}),
                propertyRentalCharacterizationAggregate:
                  propertyDetails[0]?.propertyInformation
                    ?.rentalCharacterization ?? "",
              },
            };
          }
          propertyDetails.forEach((propDetail: ObjectType, propIdx: number) => {
            if (
              propDetail?.propertyInformation?.monthlyPropertyGrossRent ||
              propDetail?.propertyInformation?.grossRent
            ) {
              tempPropBody[propIdx + 1] = {
                loanEconomics: {
                  ...tempPropBody[propIdx + 1]?.loanEconomics,
                  monthlyPropertyGrossRent:
                    propDetail?.propertyInformation?.monthlyPropertyGrossRent ||
                    propDetail?.propertyInformation?.grossRent,
                },
              };
            }
            if (propDetail?.propertyInformation?.rentalCharacterization) {
              tempPropBody[propIdx + 1] = {
                loanEconomics: {
                  ...tempPropBody[propIdx + 1]?.loanEconomics,
                  percentOccupied:
                    propDetail?.propertyInformation?.rentalCharacterization?.includes(
                      "Occupied"
                    )
                      ? 1.0
                      : 0,
                },
              };
            }
          });
          if (body?.borrowerInformation?.firstTimeHomeBuyer) {
            reqBody.loanFact.loan.loanUserMap[0].firstTimeHomeBuyer =
              body?.borrowerInformation?.firstTimeHomeBuyer;
          }
          if (body?.loanInformation?.loanPurpose) {
            resultObj.loanResult[loanId] = {
              ...resultObj.loanResult[loanId],
              loanCharacterization: {
                finalLoanCharacterization:
                  body?.loanInformation?.loanPurpose ?? null,
              },
            };
          }

          if (body?.loanEconomics?.requestedAsIsLTV) {
            resultObj.loanResult[loanId] = {
              ...resultObj.loanResult[loanId],
              loanFeatures: {
                ...(resultObj.loanResult[loanId].loanFeatures ?? {}),
                originalLtv: +body?.loanEconomics?.requestedAsIsLTV / 100,
              },
            };
          }
          if (body?.loanEconomics?.requestedLTPP) {
            resultObj.loanResult[loanId] = {
              ...resultObj.loanResult[loanId],
              loanFeatures: {
                ...(resultObj.loanResult[loanId]?.loanFeatures ?? {}),
                originalLtc: +body?.loanEconomics?.requestedLTPP / 100,
              },
            };
          }

          if (Object.keys(tempPropBody).length) {
            reqBody.resultObject = {
              ...reqBody.resultObject,
              propertiesResults: tempPropBody,
            };
          }
          if (Object.keys(resultObj.loanResult[loanId]).length) {
            reqBody.resultObject = {
              ...reqBody.resultObject,
              ...resultObj,
            };
          }
        }
        break;
      default:
        break;
    }
    return reqBody;
  } catch (err) {
    console.error(err);
  }
};

const getAdjustmentsData = (
  body: ObjectType,
  isDSCR: boolean,
  shouldMultiplyBy100: boolean = true
) => {
  const tableBody: any = [];
  const multiplyBy = shouldMultiplyBy100 ? 100 : 1;
  Object.keys(body)
    ?.filter((str: string) => !adjustmentKeysToBeExcluded?.includes(str))
    ?.forEach((ele: any) => {
      const key = labelFieldAdjustmentMapping[ele];
      const value =
        typeof +body[ele] === "number" &&
        !isNaN(+body[ele]) &&
        body[ele] &&
        body[ele] !== null
          ? `${(+body[ele] * multiplyBy)?.toFixed(4)} %`
          : null;
      const excludeLeverageKeys = [
        "Price Adjustment ",
        "Final Toorak Price ",
        "Total Adjustments ",
        "Total Market Data Deductions",
      ];

      if (value) {
        if (isDSCR) {
          if (!excludeLeverageKeys.includes(key)) {
            tableBody.push([key, value, value]);
          } else return;
        } else {
          tableBody.push([key, value]);
        }
      }
    });
  return tableBody;
};

const getBridgePricingData = (body: ObjectType, isGroundUp: boolean) => {
  const returnArray: any = [];
  labelFieldBridgePriceMapping.forEach((field: ObjectType) => {
    let value = body[field.field];
    if (+value) {
      value = getPercentageValue(value);
    } else {
      value = null;
    }

    if (field.isVisible(isGroundUp) && value) {
      returnArray.push([field.label, value]);
    }
  });
  return returnArray;
};

const getFinalPricingTable = (body: any) => {
  const returnArr: any = [];
  Object.keys(finalPricingTables as ObjectType).forEach((key: string) => {
    const valueKey: string = finalPricingTables[key];
    if (body?.[valueKey]) {
      returnArr.push([
        key,
        valueKey === "actualInterestRate"
          ? `${(body?.[valueKey] * 100)?.toFixed(4)} %`
          : body?.[valueKey],
      ]);
    }
  });
  return returnArr;
};
const getPricingData = (body: ObjectType, isDSCR: boolean) => {
  const returnArr: any = [];
  labelFieldPricingMapping?.forEach((field: ObjectType) => {
    let value: any =
      field.fieldType === "percentage" && body?.[field?.field]
        ? field?.convertToPercentage
          ? `${(body?.[field?.field] * 100).toFixed(4)} %`
          : `${body[field.field].toFixed(4)} %`
        : sanitizeData(body?.[field?.field], false);
    if (!+body?.[field?.field]) {
      value = null;
    }

    if (value) {
      const returnObjArr = [field.label, value];
      returnArr.push(returnObjArr);
    }
  });
  return returnArr;
};

const getSum = (a: any, b: any) => {
  if (!isNaN(a) && !isNaN(b)) {
    return +a + +b;
  }
  return 0;
};

export const getTableValue = (
  reqValue: number,
  adjustedValue: number,
  toBeFixed: boolean
) => {
  if (isFalsyButNotZero(reqValue)) {
    if (
      typeof +reqValue === "number" &&
      typeof +adjustedValue === "number" &&
      !isNaN(+reqValue) &&
      !isNaN(+adjustedValue)
    ) {
      if (+reqValue < +adjustedValue) {
        return toBeFixed
          ? `-${Number(reqValue)?.toFixed(2)}`
          : `-${Number(reqValue)}`;
      } else if (+reqValue > +adjustedValue) {
        return toBeFixed
          ? `+${Number(reqValue)?.toFixed(2)}`
          : `+${Number(reqValue)}`;
      }
    }
  }

  return reqValue ?? "";
};

const getFormattedNumber = (value: any) => {
  if (!isNaN(Number(value)) && value) {
    return Number(value)?.toFixed(2);
  }
  return value || "-";
};

const getProceedsData = (
  body: ObjectType,
  loanData: any,
  type: string,
  isMutlifamily: boolean
) => {
  let tableBody: any = [];
  switch (type) {
    case "Rehab":
    case "Fix & Flip":
    case "Stabilized":
      tableBody = [
        {
          id: "max",
          label: "max",
          data: [
            "Max",
            getFormattedNumber(body?.maxLeverage?.maxInitialLoanFunding) ?? "",
            getFormattedNumber(body?.finalLeverage?.rehabAmount) ?? "",
            getFormattedNumber(body?.maxLeverage?.maxTotalMaxLoanAmount) ?? "",
          ],
        },
        {
          id: "adjustedMax",
          label: "adjustedMax",
          data: [
            "Adjusted Max",
            getFormattedNumber(body?.finalLeverage?.initialLoanFunding),
            getFormattedNumber(body?.finalLeverage?.rehabAmount),
            getFormattedNumber(body?.finalLeverage?.totalMaxLoanAmount),
          ],
        },
        {
          id: "requested",
          label: "requested",
          data: [
            "Requested",
            getTableValue(
              loanData?.loanEconomics?.initialLoanAmount,
              body?.finalLeverage?.initialLoanFunding,
              true
            ) ?? "",
            getTableValue(
              isMutlifamily
                ? getSum(
                    loanData?.loanEconomics?.hardCosts ?? 0,
                    (loanData?.loanEconomics?.softCosts ?? 0) ||
                      (loanData?.loanEconomics?.softCostsCap15Rehab ?? 0)
                  )
                : loanData?.loanEconomics?.totalRehabBudget,
              Number(body?.finalLeverage?.rehabAmount),
              true
            ) ?? "",
            getTableValue(
              getSum(
                loanData?.loanEconomics?.initialLoanAmount ?? 0,
                isMutlifamily
                  ? getSum(
                      loanData?.loanEconomics?.hardCosts ?? 0,
                      (loanData?.loanEconomics?.softCosts ?? 0) ||
                        (loanData?.loanEconomics?.softCostsCap15Rehab ?? 0)
                    )
                  : loanData?.loanEconomics?.totalRehabBudget ?? 0
              ),
              // body?.loanFeatures?.requestedTotalMaxLoanAmount,
              body?.finalLeverage?.totalMaxLoanAmount,
              true
            ) ?? "",
          ],
        },
      ];
      break;
    case "Ground Up":
      const reqRehab = getSum(
        loanData?.loanEconomics?.hardCosts,
        loanData?.loanEconomics?.softCosts
      );
      const initialPlusRehab = getSum(
        body?.finalLeverage?.rehabAmount,
        body?.finalLeverage?.initialLoanFunding
      );
      tableBody = [
        {
          id: "initialProceeds",
          label: "initialProceeds",
          data: [
            "Initial Proceeds",
            getTableValue(
              loanData?.loanEconomics?.initialLoanAmount,
              body?.finalLeverage?.initialLoanFunding,
              true
            ) ?? "",
            Number(body?.finalLeverage?.initialLoanFunding)?.toFixed(2) ?? "",
          ],
        },
        {
          id: "rehabAmount",
          label: "rehabAmount",
          data: [
            "Rehab Amount",
            getTableValue(reqRehab, body?.finalLeverage?.rehabAmount, true) ??
              "",
            Number(body?.finalLeverage?.rehabAmount)?.toFixed(2) ?? "",
          ],
        },
        {
          id: "initialLoanPlusRehabAmount",
          label: "initialLoanPlusRehabAmount",
          data: [
            "Initial Loan + Rehab Amount",
            getTableValue(
              getSum(loanData?.loanEconomics?.initialLoanAmount, reqRehab),
              initialPlusRehab,
              true
            ) ?? "",
            Number(
              getSum(
                body?.finalLeverage?.rehabAmount,
                body?.finalLeverage?.initialLoanFunding
              )
            ).toFixed(2) ?? "",
          ],
        },

        {
          id: "interestReserve",
          label: "interestReserve",
          data: [
            "Interest Reserve",
            getTableValue(
              loanData?.loanEconomics?.interestReserve,
              body?.finalLeverage?.interestReserveProceeds,
              true
            ) ?? "",
            Number(body?.finalLeverage?.interestReserveProceeds)?.toFixed(2) ??
              "",
          ],
        },
        {
          id: "totalMaxLoanAmount",
          label: "totalMaxLoanAmount",
          data: [
            "Total Max Loan Amount",
            getTableValue(
              getSum(
                getSum(loanData?.loanEconomics?.initialLoanAmount, reqRehab),
                loanData?.loanEconomics?.interestReserve ?? 0
              ),
              body?.finalLeverage?.totalMaxLoanAmount,
              true
            ) ?? "",
            Number(body?.finalLeverage?.totalMaxLoanAmount)?.toFixed(2) ?? "",
          ],
        },
      ];
      break;
    default:
      break;
  }
  return {
    tableBody,
  };
};

export const getPercentageValue = (
  value: any,
  nonPercent?: boolean,
  toFixedVal?: number,
  shouldMultiply?: boolean
) => {
  let returnValue: any = null;
  const tempNonPercent = nonPercent ?? false;
  const tempToFixedVal = toFixedVal ?? 2;
  const tempShouldMultiply = shouldMultiply ?? true;
  let tempValue = value;

  let isSymbol: any = null;
  if (/[+-]/.test(tempValue)) {
    isSymbol = `${tempValue}`?.charAt(0);
    tempValue = `${tempValue}`?.slice(1);
  }

  if (isFalsyButNotZero(tempValue)) {
    if (typeof tempValue === "string" && isNaN(+tempValue)) {
      returnValue = tempValue;
    } else if (+tempValue < 100) {
      if (tempShouldMultiply) {
        if (tempNonPercent) {
          returnValue = (+tempValue * 100).toFixed(tempToFixedVal);
        } else {
          returnValue = `${(+tempValue * 100).toFixed(tempToFixedVal)} %`;
        }
      } else {
        returnValue = `${(+tempValue).toFixed(tempToFixedVal)} %`;
      }
    } else {
      returnValue = `${tempValue} %`;
    }
  }
  return isSymbol ? `${isSymbol}${returnValue}` : returnValue ?? "";
};

export const getLeverageTableBody = (
  loanStage: string,
  body: any,
  loanData?: any
) => {
  switch (loanStage) {
    case "Rehab":
    case "Fix & Flip":
    case "Stabilized":
      return [
        {
          id: "max",
          label: "max",
          borderColor: "safronBorder",
          data: [
            "Max",
            getPercentageValue(body?.maxLeverage?.maxLTVAsIs),
            getPercentageValue(body?.maxLeverage?.maxLTVARV),
            getPercentageValue(body?.maxLeverage?.maxLTCOne),
          ],
        },
        {
          id: "adjustedMax",
          label: "adjustedMax",
          borderColor: "skyBlueBorder",
          data: [
            "Adjusted Max",
            getPercentageValue(body?.finalLeverage?.afterAdjustmentMaxLTVAsIs),
            getPercentageValue(body?.finalLeverage?.afterAdjustmentMaxARV),
            getPercentageValue(body?.finalLeverage?.afterAdjustmentMaxLTC),
          ],
        },
        {
          id: "requested",
          label: "requested",
          borderColor: "blueBorder",
          data: [
            "Requested",
            getPercentageValue(
              getTableValue(
                body?.loanFeatures?.originalAsIsLtv,
                Number(body?.finalLeverage?.afterAdjustmentMaxLTVAsIs),
                false
              )
            ),
            getPercentageValue(
              getTableValue(
                body?.loanFeatures?.originalAsIsRepairedLtv,
                Number(body?.finalLeverage?.afterAdjustmentMaxARV),
                false
              )
            ),
            getPercentageValue(
              getTableValue(
                body?.loanFeatures?.originalLtc,
                Number(body?.finalLeverage?.afterAdjustmentMaxLTC),
                false
              )
            ),
          ],
        },
        // {
        //   id: "toorakFinalLeverage",
        //   label: "toorakFinalLeverage",
        //   borderColor: "greenBorder",
        //   data: [
        //     "Toorak Final Leverage",
        //     getPercentageValue(body?.finalLeverage?.finalLTVAsIs),
        //     getPercentageValue(body?.finalLeverage?.finalARV),
        //     getPercentageValue(body?.finalLeverage?.finalLTC),
        //   ],
        // },
      ];
    case "MultiFamily":
    case "Ground Up":
      return [
        {
          id: "max",
          label: "max",
          borderColor: "safronBorder",
          data: [
            "Max",
            getPercentageValue(body.maxLeverage.maxLTVAsIs),
            getPercentageValue(body.maxLeverage.arvLTVAsCompleted),
            getPercentageValue(body.maxLeverage.maxLTCAsIs),
            getPercentageValue(body.maxLeverage.maxBlendedLTCAsCompleted),
            getPercentageValue(
              body.maxLeverage.maxBlendedLTCWithFundedIRAsCompleted
            ),
          ],
        },
        {
          id: "adjustedMax",
          label: "adjustedMax",
          borderColor: "skyBlueBorder",
          data: [
            "Adjusted Max",
            getPercentageValue(body.finalLeverage.afterAdjustmentMaxLTVAsIs),
            getPercentageValue(body.finalLeverage.afterAdjustmentMaxARV),
            getPercentageValue(body.finalLeverage.afterAdjustmentMaxLTC),
            getPercentageValue(body.finalLeverage.afterAdjustmentMaxBlendedLTC),
            getPercentageValue(
              body.finalLeverage.afterAdjustmentMaxBlendedLTCWithIR
            ),
          ],
        },
        {
          id: "requested",
          label: "requested",
          borderColor: "blueBorder",
          data: [
            "Requested",
            getPercentageValue(
              getTableValue(
                body.loanFeatures.originalAsIsLtv,
                Number(body.finalLeverage.afterAdjustmentMaxLTVAsIs),
                false
              )
            ),
            getPercentageValue(
              getTableValue(
                body.loanFeatures.arvLtvAsCompleted,
                Number(body.finalLeverage.afterAdjustmentMaxARV),
                false
              )
            ),
            getPercentageValue(
              getTableValue(
                body.loanFeatures.originalLtc,
                Number(body.finalLeverage.afterAdjustmentMaxLTC),
                false
              )
            ),
            getPercentageValue(
              getTableValue(
                body.loanFeatures.blendedLtcAsCompleted,
                Number(body.finalLeverage.afterAdjustmentMaxBlendedLTC),
                false
              )
            ),
            getPercentageValue(
              getTableValue(
                body.loanFeatures.blendedLTCWithFundedIRAsCompleted,
                Number(body.finalLeverage.afterAdjustmentMaxBlendedLTCWithIR),
                false
              )
            ),
          ],
        },
        {
          id: "toorakFinalLeverage",
          label: "toorakFinalLeverage",
          borderColor: "greenBorder",
          data: [
            "Toorak Final Leverage",
            getPercentageValue(body?.finalLeverage?.finalLTVAsIs),
            getPercentageValue(body?.finalLeverage?.finalARV),
            getPercentageValue(body?.finalLeverage?.finalLTC),
            getPercentageValue(body?.finalLeverage?.finalBlendedLTC),
            getPercentageValue(body?.finalLeverage?.finalBlendedLTCWithIR),
          ],
        },
      ];
    default:
      return [
        {
          id: "max",
          label: "max",
          borderColor: "safronBorder",
          data: [
            "Max",
            getPercentageValue(body?.maxLeverage?.maxAsIsLTV),
            getPercentageValue(body?.maxLeverage?.maxAsIsLTV), //maxLTC
          ],
        },
        {
          id: "adjustedMax",
          label: "adjustedMax",
          borderColor: "greenBorder",
          data: [
            "Adjusted Max",
            getPercentageValue(body?.toorakProceeds?.toorakAdjustedAsIsLtv),
            getPercentageValue(body?.toorakProceeds?.toorakAdjustedAsIsLtv), // toorakAdjustedAsIsLtc
          ],
        },
        {
          id: "requested",
          label: "requested",
          borderColor: "blueBorder",
          data: [
            "Requested",
            getPercentageValue(
              getTableValue(
                getValue(
                  body?.loanFeatures?.originalAsIsLtv,
                  loanData?.loanEconomics?.requestedAsIsLTV
                ),
                body?.toorakProceeds?.toorakAdjustedAsIsLtv,
                false
              )
            ),
            getPercentageValue(
              getTableValue(
                getValue(
                  body?.loanFeatures?.originalLtc,
                  loanData?.loanEconomics?.requestedLTPP
                ),
                body?.toorakProceeds?.toorakAdjustedAsIsLtv,
                false
              )
            ),
          ],
        },
      ];
  }
};

export const normalizeEvaluationResult = (
  body: ObjectType,
  loanType: string,
  loanData: ObjectType,
  fetchData: ObjectType,
  failedCountTests: number | null
) => {
  const dataToBeChecked =
    loanData?.sizingType === SizingTypeValue?.QUICK_LOAN_SIZING
      ? loanData?.quickLoanSizingData
      : loanData?.sizingType === SizingTypeValue?.DETAILED_LOAN_SIZING
      ? loanData?.detailedLoanSizingData
      : loanData;
  let resultData = {};
  if (Object.keys(body).length) {
    switch (loanType) {
      case "BRIDGE":
        let regToorakYield = body?.toorakInterests?.regularToorakYield
          ? body?.toorakInterests?.regularToorakYield * 100
          : 0;
        if (body?.toorakInterests?.regularToorakYield) {
          regToorakYield =
            regToorakYield * 100 +
            (+dataToBeChecked?.loanEconomics?.brokerFee || 0);
        }
        // (body?.toorakInterests?.regularToorakYield ? (getPercentageValue(regToorakYield, false, 4, false)) : null) ||
        const finalRate =
          (body?.toorakInterests?.regularToorakYield
            ? getPercentageValue(
                body?.toorakInterests?.regularToorakYield,
                false,
                4
              )
            : null) ||
          (getPercentageValue(
            dataToBeChecked?.loanEconomics?.interestRate ||
              dataToBeChecked?.loanEconomics?.originalInterestRate,
            false,
            4,
            false
          ) ??
            null);
        const bridgeAdjustments = getAdjustmentsData(
          body?.discounts,
          false,
          true
        );
        resultData = {
          leverageCards: {
            maxLoanAmount:
              sanitizeData(body.finalLeverage.totalMaxLoanAmount, true, 2) ??
              "",
            finalRate: finalRate ?? "",
            priceAdjustment: getPercentageValue(
              +body?.toorakInterests?.finalToorakYield -
                +body?.toorakInterests?.regularToorakYield
            ),
            interestRate: body?.loanPricing?.interestOnlyRate.toFixed(4),
            pricingType: body?.toorakInterests?.rateSheetType,
          },
          riskScoreCards: {
            riskScore: body?.riskScores?.totalAggregateRisk,
            riskBucket: body?.riskScores?.riskBucket,
            riskScoresVersion: body?.riskScores?.riskScoresVersion,
            pd: `${(
              parseFloat(body?.riskScores?.totalAggregateRisk ?? "0.0") * 100
            ).toFixed(3)}%`,
          },
          leverages: {
            tableBody: getLeverageTableBody(fetchData?.loanType, body),
          },
          leverageAdjustment: {
            isLeverageAdjustment: bridgeAdjustments?.length > 0,
            totalAdjustments:
              (body?.discounts?.totalMarketDataDeductions || 0) * 100, // keep as number
            totalNegative:
              (body?.discounts?.totalMarketDataDeductions || 0) * 100,
            totalPositive: "0.0000",
            tableBody: bridgeAdjustments,
          },
          profitAndRentYield: {
            profitability: getPercentageValue(
              body?.guidelineOutput?.profitabilityCalculation ||
                body?.guidelineOutput?.profitability,
              false,
              4
            ),
            rentYield:
              body.guidelineOutput.rentYieldCalculation ||
              body.guidelineOutput.rentYield,
          },
          proceeds: getProceedsData(
            body,
            dataToBeChecked,
            fetchData?.loanType,
            [
              "Mixed Use",
              "5+ Unit Multifamily",
              "Multifamily/MixedUse",
            ].includes(fetchData?.propertyType ?? "")
          ),
          pricing: {
            tableBody: getBridgePricingData(
              body?.toorakInterests,
              fetchData?.loanType === "Ground Up"
            ),
          },
        };
        return resultData;
      case "30Year":
        const maxLoanAmount = isFalsyButNotZero(
          body?.toorakProceeds?.toorakLoanAmount
        )
          ? body?.toorakProceeds?.toorakLoanAmount
          : dataToBeChecked?.loanEconomics?.borrowerRequested ||
            dataToBeChecked?.loanInformation?.borrowerRequested;
        const dscrAdjustments = getAdjustmentsData(
          body?.adjustmentsResult30Yr,
          true,
          true
        );

        const interestRate = body?.loanPricing?.actualInterestRate
          ? (body?.loanPricing?.actualInterestRate * 100).toFixed(4)
          : dataToBeChecked?.loanEconomics?.originalInterestRate ||
            dataToBeChecked?.loanInformation?.originalInterestRate;

        resultData = {
          leverageCards: {
            maxLoanAmount: isFalsyButNotZero(
              body?.toorakProceeds?.toorakLoanAmount
            )
              ? `${sanitizeData(maxLoanAmount, true, 2)}`
              : null,
            finalPrice: body?.loanPricing?.finalToorakPrice,
            interestRate: interestRate ? `${interestRate} %` : "",
            dscr: body?.loanEconomics?.dscr,
            prepaymentPenaltyTypeMonths:
              dataToBeChecked?.loanEconomics?.prepaymentPenaltyTypeMonths ||
              dataToBeChecked?.loanInformation?.prepaymentPenaltyTypeMonths,
            failedTests: failedCountTests ?? null,
            prepayPenaltyType:
              (dataToBeChecked?.loanEconomics?.prepayPenaltyType ||
                dataToBeChecked?.loanInformation?.prepayPenaltyType) ??
              prePayTypeToMonthsMap?.[
                dataToBeChecked?.loanEconomics?.prepaymentPenaltyTypeMonths ||
                  dataToBeChecked?.loanInformation
                    ?.prepaymentPenaltyTypeMonths ||
                  0
              ],
          },
          leverages: {
            tableBody: getLeverageTableBody(
              fetchData?.loanType,
              body,
              dataToBeChecked
            ),
          },
          leverageAdjustment: {
            isLeverageAdjustment: dscrAdjustments?.length > 0,
            totalAdjustments: body?.adjustmentsResult30Yr?.totalAdjustments
              ? body?.adjustmentsResult30Yr?.totalAdjustments * 100
              : null, // keep as number
            totalNegative: body?.adjustmentsResult30Yr?.totalAdjustments
              ? body?.adjustmentsResult30Yr?.totalAdjustments * 100
              : null,
            totalPositive: "0.0000",
            tableBody: dscrAdjustments,
          },
          requiredReserves: {
            minimumRequiredReserves: body?.loanEconomics
              ?.minimumBorrowerLiquidity
              ? `${sanitizeData(
                  body?.loanEconomics?.minimumBorrowerLiquidity,
                  true,
                  2
                )}`
              : "",
            dscr: isFalsyButNotZero(body?.loanEconomics?.dscr)
              ? body?.loanEconomics?.dscr
              : dataToBeChecked?.loanEconomics?.dscr,
            failedTests: failedCountTests ?? null,
          },
          finalPriceTable: {
            rateAsOfDate: "",
            finalPriceRate: body?.loanPricing?.finalToorakPrice,
            totalAdjusment: body?.adjustmentsResult30Yr?.totalAdjustments
              ? `${body?.adjustmentsResult30Yr?.totalAdjustments * 100} %`
              : "",
            tableBody: getFinalPricingTable(body?.loanPricing),
          },
          pricing: {
            tableBody: getPricingData(body?.loanPricing, true),
          },
          proceeds: {},
        };
        return resultData;
      default:
        break;
    }
    return {};
  }
};

export const normalizeSubmitApplicationLoanData = (
  loanData: any,
  loanId: string,
  loanTypeId: number,
  condoEligibilityFormData: ObjectType,
  autoCalculatedLoanResults: ObjectType,
  fetchData: ObjectType,
  loanDetails?: any,
  latestTermSheetVersion?: any
) => {
  let loanSizerType = null;
  let loanType = "BridgeLoan";
  if (fetchData?.productType === "Bridge Loan") {
    if (fetchData?.loanType === "Ground Up") {
      loanSizerType = "Ground-Up";
    } else if (
      ["Mixed Use", "5+ Unit Multifamily", "Multifamily/MixedUse"].includes(
        fetchData?.propertyType ?? ""
      )
    ) {
      loanSizerType = "Multifamily/MixedUse";
    } else {
      loanSizerType = "1-4 Family";
    }
  } else {
    loanType = "30 Year Loan";
  }

  const datas = window.location?.pathname?.split("/");
  const tempOriginatorData: string | ObjectType =
    getCookie("originatorData") ||
    localStorage?.getItem("originatorData") ||
    "{}";
  const originatorData = JSON.parse(tempOriginatorData);

  let experience =
    loanData?.borrowerInformation?.experience ||
    loanData?.borrowerInformation?.propertiesCompletedWithinLast36Months ||
    loanData?.borrowerInformation?.borrowerGUCExperience;
  if (hasOnlySpaces(experience) || experience?.includes(".")) {
    experience = null;
  }

  const totalBudgetAmount =
    loanData?.loanEconomics?.totalRehabBudget ||
    +loanData?.loanEconomics?.hardCosts + +loanData?.loanEconomics?.softCosts ||
    +loanData?.loanEconomics?.hardCosts +
      +loanData?.loanEconomics?.softCostsCap15Rehab ||
    loanData?.loanEconomics?.totalBudget ||
    "0";

  let foreignNational = loanData?.borrowerInformation?.borrowerGuarantor;

  if (loanType === "30 Year Loan") {
    foreignNational =
      loanData?.borrowerInformation?.immigrationStatus ??
      loanData?.borrowerInformation?.citizenshipStatus;
    if (loanData?.borrowerInformation?.borrowerGuarantor === "Yes") {
      if (
        loanData?.borrowerInformation?.immigrationStatus.includes("Foriegn") ||
        loanData?.borrowerInformation?.immigrationStatus === ""
      ) {
        foreignNational = "Yes";
      }
    } else if (loanData?.borrowerInformation?.borrowerGuarantor === "No") {
      foreignNational = "No";
    }
  }

  const borrowerProceeds =
    loanData?.loanInformation?.borrowerProceeds ||
    loanData?.loanInformation?.cashOutRefinance;
  const returnData: ObjectType = {
    loanId,
    loanTypeId,
    loanSizerType,
    loanType,
    loanExcelJson: "",
    onlineSizerMetaData: {
      evaluationId: loanDetails?.evaluationID,
      termSheetVersion: latestTermSheetVersion || "",
      urlMeta: {
        productType: loanDetails?.productType?.replace(" ", ""),
        loanType: loanDetails?.productType?.includes("DSCR")
          ? "dscr"
          : "bridge",
        loanUuid: datas[datas?.length - 1],
        url: window.location.href,
      },
    },
    loanEconomics: {
      totalBudgetAmount: totalBudgetAmount ? `${totalBudgetAmount}` : "0",
      originalLoanAmount:
        (loanData?.loanEconomics?.originalLoanAmount?.toString() ||
          loanData?.loanEconomics?.initialLoanAmount?.toString() ||
          loanData?.loanEconomics?.borrowerRequested?.toString()) ??
        null,
      rehabAmount:
        (loanData?.loanEconomics?.totalRehabBudget
          ? `${loanData?.loanEconomics?.totalRehabBudget}`
          : totalBudgetAmount
          ? `${totalBudgetAmount}`
          : null) ?? null,
      existDebt: loanData?.loanEconomics?.exitDebt ?? null,
      softCost:
        (loanData?.loanEconomics?.softCosts ||
          loanData?.loanEconomics?.softCostsCap15Rehab) ??
        null,
      hardCost: loanData?.loanEconomics?.hardCosts ?? null,
      interestRateAsOfCutOffDate:
        (+loanData?.loanEconomics?.interestRate ||
          +loanData?.loanEconomics?.originalInterestRate) ??
        null,
      prepaymentPenaltyMonths:
        loanData?.loanEconomics?.prepaymentPenaltyTypeMonths ?? null,
      prepayPenaltyType: loanData?.loanEconomics?.prepayPenaltyType ?? null,
      rateType: loanData?.loanEconomics?.rateType ?? "Fixed",
      interestReserve: loanData?.loanEconomics?.interestReserve ?? null,
      // totalRehabBudget:
      //   (loanData?.loanEconomics?.totalRehabBudget ||
      //     +loanData?.loanEconomics?.hardCosts +
      //       (+loanData?.loanEconomics?.softCosts ||
      //         +loanData?.loanEconomics?.softCostsCap15Rehab)) ??
      //   null,
      rehabBudget: totalBudgetAmount ? `${totalBudgetAmount}` : 0,
      interestOnlyPeriod:
        loanData?.loanEconomics?.interestOnlyFlag === "No"
          ? 0
          : loanData?.loanEconomics?.ioPeriod,
      totalOriginationAndDiscountPoints:
        (loanData?.loanEconomics?.totalOrgAndDiscountPoints ||
          loanData?.loanEconomics?.orgBrokerFee) ??
        null,
      includeOutOfPocketBudgetInARLTV:
        loanData?.loanEconomics?.includeOutOfPocketBudgetARLTV ?? true,
    },
    loanInfo: {
      primaryLoanId: loanData?.loanInformation?.originatorLoanID ?? "",
      loanStructure: loanData?.loanInformation?.loanStructure ?? null,
      loanPurpose:
        (loanData?.loanInformation?.loanPurpose ||
          autoCalculatedLoanResults?.loanInformation?.loanPurpose) ??
        null,
      cashoutFlag: loanData?.loanInformation?.cashOutFlag ?? null,
      recourseString: loanData?.loanInformation?.recourse ?? null,
      pledgeOfEquity:
        yesornToBoolean(loanData?.loanInformation?.pledgeOfEquity) ?? null,
      isPermitApprovalsAndFullPlansInPlace:
        loanData?.loanInformation?.permitApprovalsAndFullPlans ?? null,
      expectedClosingDate:
        getFormattedDate(loanData?.loanInformation?.expectedClosingDate) ??
        null,
      irFundingSource: loanData?.loanInformation?.irFundingSource ?? null,
      sizerRateLockPeriod:
        loanData?.loanInformation?.sizerRateLockPeriod ?? null,
      borrowerProceeds:
        hasOnlySpaces(borrowerProceeds) || borrowerProceeds?.includes(".")
          ? null
          : borrowerProceeds === "Y"
          ? "Yes"
          : "No",
      noOfProperties:
        hasOnlySpaces(loanData?.loanInformation?.numberOfProperties) ||
        loanData?.loanInformation?.numberOfProperties?.includes(".")
          ? null
          : parseInt(loanData?.loanInformation?.numberOfProperties) ?? null,
      predominantState: loanData?.loanInformation?.predominantState || null,
      predominantPropertyType:
        loanData?.loanInformation?.predominantPropertyType || null,
      originationDate: loanData?.loanInformation?.originationDate ?? null,
      loanTermMonthly:
        (loanData?.loanInformation?.loanTerm
          ? Number(loanData?.loanInformation?.loanTerm)
          : null) ?? null,
      calculatedCondoEligibility:
        loanData?.loanInformation?.condoEligibility ?? null,
      condoEligibility: loanData?.loanInformation?.condoEligibility ?? null,
      exitStrategy: loanData?.loanInformation?.exitStrategy ?? null,
      crossCollaterlization:
        yesornToBoolean(loanData?.loanInformation?.crossCollaterlization) ??
        null,
      creditEvent: loanData?.loanInformation?.creditEvent ?? "No",
      takeoutPartner: loanData?.takeOutPartner,
      takeoutPartnerStatus: loanData?.takeoutPartnerStatus,
    },
    loanUserMap: [
      {
        loanUserType: "Borrower",
        loanUserSequence: "1",
        isPrimary: true,
        originalCreditScoreMedian:
          loanData?.borrowerInformation?.qualifyingCreditScore ||
          loanData?.borrowerInformation?.originalCreditScore ||
          loanData?.borrowerInformation?.creditScoreActual,
        originalCreditScoreReportDate: null,
        experience,
        inState: loanData?.borrowerInformation?.inState ?? null,
        borrowerGUCExperience:
          (hasOnlySpaces(
            loanData?.borrowerInformation?.borrowerGUCExperience
          ) ||
          loanData?.borrowerInformation?.borrowerGUCExperience?.includes(".")
            ? null
            : loanData?.borrowerInformation?.borrowerGUCExperience) ?? null,
        heavyRehabExperience:
          hasOnlySpaces(loanData?.borrowerInformation?.heavyRehabExperience) ||
          loanData?.borrowerInformation?.heavyRehabExperience?.includes(".")
            ? null
            : loanData?.borrowerInformation?.heavyRehabExperience ?? null,
        ownershipOfEntity: null,
        customer: {
          accountName: "",
          accountType:
            loanData?.borrowerInformation?.borrowerType === "Entity"
              ? "BORROWER"
              : "person",
          addressList: [],
          contactList: [],
          dateOfBirth: null,
          firstName: "",
          middleName: null,
          lastName: null,
          partyType:
            loanData?.borrowerInformation?.borrowerType === "Entity"
              ? "account"
              : "person",
          foreignNationalString: foreignNational ?? null,
        },
        firstTimeHomeBuyer:
          loanData?.borrowerInformation?.firstTimeHomeBuyer ?? null,
      },
    ],
    loanConfig: {},
    loanCondominium: condoEligibilityFormData,
    loanDetailId: {
      loanId: loanId,
      toorakLoanId: loanId,
      toorakLoanIdSequence: "92552",
      loanIdPrefix: loanType === "BridgeLoan" ? "BR" : "TY",
      tradeId: null,
      originatorPartyId: "",
      loanConfigId: {
        dueDiligencePartyId: null,
        sellerPartyId: null,
        spocPartyId: null,
        servicerPartyId: null,
        servicerName: "BSI",
        ttfVersionId: 1,
        tapeToFile: "No",
        rateLockRateAdjustment: null,
        created_by: originatorData?.partyId ?? "",
        updated_by: null,
        deleted_on: null,
        appraisal: null,
        streetView: null,
        zillow: null,
        truliaCrime: null,
        emailSentToOriginator: null,
        datePurchased: null,
        ruleSetVersion: null,
        latAnalystPartyId: null,
        latAnalystApproverPartyId: null,
        status: null,
        comments: null,
        toorakEligibility: null,
        custodyDocuments: null,
        appraiserList: null,
        rateLockPeriod: null,
        sizerRateLockPeriod: null,
        rateAdjustmentLockDate: null,
        closingLegalFirm: null,
        closingEscrowCompany: null,
        wireToLegalOrEscrow: null,
        enableBudgetReview: null,
        created_on: new Date(),
        updated_on: new Date(),
        loanConfigId: 128355,
        dateUploaded: new Date(),
      },
      loanSummaryId: {
        documentsSplitStatus: "Pending",
        documentsTaggingStatus: "Pending",
        ttfReviewStatus: "Pending",
        teamIndiaTapetoFile: "No",
        created_by: originatorData?.partyId ?? "", // originator ID
        updated_by: null,
        deleted_on: null,
        monthlyTaxAmount: null,
        escrowTaxes: null,
        monthsOfTaxes: null,
        escrowInsurance: null,
        monthsOfInsurance: null,
        taxesAndInsuranceCollected: null,
        MonthlyInsuranceAmount: null,
        taxes: null,
        insurance: null,
        taxesandInsuranceDue: null,
        escrowFund: null,
        lastPaymentDate: null,
        nextPaymentDue: null,
        updatedEscrowBalance: null,
        updatedTaxEscrowBalance: null,
        updatedInsuranceEscrowBalance: null,
        interestRateStepUp: null,
        daysUntil: null,
        percentageStepUp: null,
        femaSearch: null,
        escrowReconciliation: null,
        leaseStatus: null,
        seasoned: null,
        paymentHistoryandUPBChecked: null,
        escrowType: null,
        nextPaymentDueDate: null,
        escrowTaxHUD: null,
        escrowFloodInsuranceHUD: null,
        escrowHazardInsuranceHUD: null,
        escrowFloodInsurance: null,
        escrowHazardInsurance: null,
        monthsOfFloodInsurance: null,
        monthsOfHazardInsurance: null,
        annualFloodInsurance: null,
        annualHazardInsurance: null,
        propertyEscrowTax: null,
        propertyHazardInsurance: null,
        propertyFloodInsurance: null,
        escrowValuesSource: null,
        correctTaxEscrow: null,
        correctFloodInsuranceEscrow: null,
        correctHazardInsuranceEscrow: null,
        escrowTaxesAtClosing: null,
        monthsOfPropertyTaxEscrow: null,
        escrowCalculatorType: null,
        insuranceAgentFirstName: null,
        insuranceAgentLastName: null,
        insuranceAgentEmail: null,
        insuranceAgentContactNumber: null,
        insuranceExpirationDate: null,
        dataReconcilationStatus: null,
        insuranceCompany: null,
        checkListOverridenBy: null,
        created_on: new Date(),
        updated_on: new Date(),
        loanSummaryId: 126580,
      },
      discountPointPercentage:
        loanData?.currentSelectedInterestRate?.discountPoints ?? null,
      discountPointAmount:
        loanData?.currentSelectedInterestRate?.discountPointsWithDollar?.replace(
          /[$,]/g,
          ""
        ) ?? null,
      // fundingType: "normal",
      created_by: originatorData?.partyId ?? "",
      updated_by: null,
      deleted_on: null,
      isWarehouseFunded: null,
      loanProcessDetailId: 132674,
      created_on: new Date(),
      updated_on: new Date(),
      loanDetailId: 128352,
      loanLandmarks: [
        {
          name: "loan_creation",
          date: new Date(),
          value: "FES",
          createdBy: originatorData?.partyId ?? "",
          comments: null,
          stage: "FES",
          loanLandmarkId: 611002,
        },
      ],
    },
    originatorInfo: { ...originatorData },
  };
  return returnData;
};

export const normalizeSubmitApplicationPropertyData = (
  propertyDetails: ObjectType[],
  loanDetails: ObjectType,
  loanId: string,
  loanTypeId: number,
  autoCalculatedPropResults: ObjectType[]
) => {
  const properties: any[] = [];

  propertyDetails.forEach((propDetail: ObjectType, propIdx: number) => {
    const {
      propertyInformation,
      propertyEconomics,
      propertyLocation,
      unitInformation,
    } = propDetail;
    let closingCosts = 0;
    const units: ObjectType[] = [];

    if (propertyEconomics?.closingCosts) {
      closingCosts = +propertyEconomics?.closingCosts?.split("%")[0];
    }

    if (unitInformation && Array.isArray(unitInformation)) {
      unitInformation?.forEach((unitInfo: ObjectType) => {
        const returnUnitObj = {
          propertyUnitId: propIdx,
          leaseEndDate: null,
          leaseStartDate: null,
          monthlyLease: "",
          leaseDurationMorethan12Mos: "",
          leaseDurationLessthan12Mos: "",
          vacancyStatus: "",
          leaseStatus: unitInfo?.rentalCharacterization?.toString() ?? null,
          unitCategory: unitInfo?.unitCategory ?? null,
          percentageOccupied: unitInfo?.percentUnitOccupied ?? null,
          marketRentMonthly: unitInfo?.monthlyMarketRent ?? null,
          currentLeaseTermMonthly:
            unitInfo?.currentLeaseTermsInMonths?.includes(".") ||
            hasOnlySpaces(unitInfo?.currentLeaseTermsInMonths)
              ? null
              : +unitInfo?.currentLeaseTermsInMonths,
          mostRecentLeaseRentMonthly: unitInfo?.inPlaceLeaseRentMonthly ?? null,
          inPlaceLeaseRentMonthly: unitInfo?.inPlaceLeaseRentMonthly ?? null,
        };
        units.push(returnUnitObj);
      });
    }

    const address = propertyLocation?.address
      ?.replace(propertyLocation?.city, "")
      ?.replace(propertyLocation?.state, "")
      ?.trim();
    const returnObj = {
      loanId,
      loanPropertyOrder: 1,
      propertyinfo: {
        propertyUnits: propertyInformation?.numberOfUnits
          ? hasOnlySpaces(propertyInformation?.numberOfUnits) ||
            propertyInformation.numberOfUnits?.includes(".")
            ? 1
            : parseInt(propertyInformation?.numberOfUnits) ?? 1
          : 1,
        propertyType: propertyInformation?.propertyType ?? null,
        changeInUseCase:
          yesornToBoolean(propertyInformation?.expansionOrChangeOfUseCase) ??
          null,
        isEntitledLand:
          propertyInformation?.entitledAsOfRightDevelopment ?? null,
        isPropertyPurchasedLast2Years:
          propertyInformation?.propertyPurchasedWithinLast2Years ?? null,
        valuationSource: propertyInformation?.valuationSource ?? null,
        isPropertyTypeCondo: propertyInformation?.propertyType === "Condo",
        isPropertyTypeTwoFourUnit:
          propertyInformation?.propertyType === "2-4 Unit",
        squareFootage:
          propertyInformation?.propertySqftFootage?.replaceAll(",", "") ?? null,
        preRehabSquareFootage:
          propertyInformation?.propertySqftFootage?.replaceAll(",", "") ?? null,
        propertyCondition: propertyInformation?.propertyCondition ?? null,
        propertyAcquisitionDate:
          getFormattedDate(propertyInformation?.originalDateOfAcquisition) ??
          null,
        isPurchasedWithDebt:
          propertyInformation?.propertyWithDebt === "Y" ? "Yes" : "No",
        recentPropertyValuationDate:
          getFormattedDate(
            propertyInformation?.recentPropValuationDate ||
              propertyInformation?.mostRecentPropertyValuationDate
          ) ?? null,
      },
      propertyLocation: {
        addressLine1: address ?? null,
        addressLine2: "",
        city: propertyLocation?.city ?? null,
        state: propertyLocation?.state ?? null,
        postalCode: propertyLocation?.postalCode ?? null,
        country: propertyLocation?.country ?? null,
        locationValidationStatus: "COMPLETE",
      },
      propertyEconomics: {
        assignmentFees: propertyEconomics?.assignmentFee ?? null,
        originalAsIsAppraisalValue:
          (propertyEconomics?.originalAsIsAppraisalValue ||
            propertyEconomics?.orgAppraisalValue ||
            propertyEconomics?.asIsValue) ??
          null,
        originalAsRepairedAppraisedValue:
          propertyEconomics?.asRepairedValueARV?.toString() ||
          propertyEconomics?.afterRepairValue ||
          null,
        purchasePrice:
          propertyEconomics?.propertyAcquisitionPrice ||
          propertyEconomics?.purchasePrice ||
          null,
        borrowerPaidCosts: propertyEconomics?.borrowerPaidCostToDate ?? null,
        grossCondoSelloutValue:
          propertyEconomics?.grossCondoSelloutValue ?? null,
        costBasis:
          autoCalculatedPropResults[propIdx]?.propertyEconomics?.costBasis ??
          null,
        closingCost: closingCosts,
        additionalEligibleCost:
          propertyEconomics?.additionalEligibleCost ?? null,
        grossPotentialRent:
          propertyEconomics?.estimatedRentPerYearUponCompletion ?? null,
        annualPropertyTaxes: propertyEconomics?.annualPropertyTaxes ?? null,
        appraisalReportedNoiNcf:
          propertyEconomics?.appraisalStabilizedNOI ?? null,
        appraisalReportedNotOrNcf:
          propertyEconomics?.appraisalStabilizedNOI ?? null,
        insurance:
          (propertyEconomics?.insurance ||
            propertyEconomics?.annualInsurance) ??
          null,
        annualHazardInsurance: propertyEconomics?.annualHazardInsurance ?? null,
        annualFloodInsurance: propertyEconomics?.annualFloodInsurance ?? null,
        annualHoaFee: propertyEconomics?.annualHOAFee ?? null,
        thirdPartyValuation:
          (propertyEconomics?.thirdPartyPopertyValue ||
            propertyEconomics?.thirdPartyValuation) ??
          null,
        thirdPartyValuationStatus:
          propertyEconomics?.thirdPartyValuationStatus ?? null,
        marketRentPerMonth:
          propertyEconomics?.grossPotentialRentMonthly ?? null,
        decliningMarkets: propertyEconomics?.decliningMarkets ?? null,
      },
      propertyUnit: units,
    };
    properties.push(returnObj);
  });

  return properties;
};

function percentageOccupiedFormula(unitInformation: any) {
  const characterization = unitInformation?.rentalCharacterization;
  if (
    [
      LeaseStatusEnum.unOcupiedLongTerm,
      LeaseStatusEnum.unOccupiedShortTerm,
    ]?.includes(characterization)
  ) {
    return "0.0000%";
  }
  if (
    [
      LeaseStatusEnum.occupiedLongTerm,
      LeaseStatusEnum.occupiedShortTerm,
    ]?.includes(characterization)
  ) {
    return "100.0000%";
  }
  return null;
}

export const normalizeResultLoanData = (
  loanData: any,
  loanResultData: any,
  loanType: string,
  propertiesResults: ObjectType,
  tempPropertiesResult: ObjectType[],
  loanTypeData: ObjectType
) => {
  const propertyResult: ObjectType[] = [];

  const propertyKeys: any = [];
  Object.keys(propertiesResults ?? {})?.forEach((key: string) => {
    propertyKeys.push(propertiesResults[key]);
  });

  let tempLoanDetails: any = {};

  let tempLoanTypeValue: any = loanType; // for 2.0
  if (loanType === "BRIDGE") {
    tempLoanTypeValue = loanTypeData?.loanType;
  }

  const loanCharacterisation =
    loanResultData?.loanCharacterization ||
    loanResultData?.loanCharacterisation ||
    loanResultData?.loanCharacterisations;

  switch (loanType) {
    case "30Year":
      tempLoanDetails = {
        ["loanEconomics"]: {
          ...tempLoanDetails["loanEconomics"],
          loanLevelOccupancyPercentage: isFalsyButNotZero(
            loanResultData?.loanEconomics?.loanLevelOccupancy
          )
            ? loanResultData?.loanEconomics?.loanLevelOccupancy * 100
            : null,
          rehabLoanAmount:
            +loanData?.loanEconomics?.hardCosts +
            +loanData?.loanEconomics?.softCosts,
          dscr: loanResultData?.loanEconomics?.dscr,
          requestedAsIsLTV: getPercentageValue(
            loanResultData?.loanFeatures?.originalAsIsLtv,
            true
          ),
        },
        ["loanFeatures"]: {
          originalAsIsLtv: getPercentageValue(
            loanResultData?.loanFeatures?.originalAsIsLtv,
            true
          ),
          originalLtcLtp: getPercentageValue(
            loanResultData?.loanFeatures?.originalLtc,
            true
          ),
        },
        ["loanCharacterisation"]: {
          toorakFICObucket: loanCharacterisation?.toorakFicoBucket ?? null,
          toorakLoanType: loanCharacterisation?.toorakLoanType ?? null,
          finalLoanCharacterisation:
            loanCharacterisation?.finalLoanCharacterization ?? null,
        },
        ["loanInformation"]: {
          loanPurpose: loanCharacterisation?.finalLoanCharacterization,
        },
      };

      Object.keys(tempPropertiesResult)?.forEach((key: string, idx: number) => {
        const property = tempPropertiesResult[idx];
        propertyResult.push({
          loanFeatures: {
            ...property?.loanFeatures,
            eSRIPopulationDensity:
              propertyKeys[idx]?.loanFeatures?.esriPopulationDensity,
            zillowCBSA: propertyKeys[idx]?.loanFeatures?.zillowCbsa,
            adjustedAsIsValue:
              propertyKeys[idx]?.loanFeatures?.adjustedAsIsValue,
          },
          propertyInformation: {
            ...property?.propertyInformation,
            precentOccupied: loanResultData?.loanEconomics?.loanLevelOccupancy
              ? loanResultData.loanEconomics.loanLevelOccupancy * 100
              : null,
          },
          unitInformation: property?.unitInformation?.map(
            (unitInfo: ObjectType, unitIdx: number) => {
              return {
                ...unitInfo,
                monthlyUnitRent:
                  propertyKeys[idx]?.[`unit${unitIdx}`]?.monthlyGrossRent,
                percentageOccupied:
                  propertyKeys[idx]?.[`unit${unitIdx}`]?.percentOccupied,
                percentUnitOccupied: percentageOccupiedFormula(unitInfo),
              };
            }
          ),
        });
      });
      break;
    case "BRIDGE":
      tempLoanDetails = {
        ...tempLoanDetails,
        ["borrowerInformation"]: {
          ...tempLoanDetails["borrowerInformation"],
          experiencedNew: loanCharacterisation?.customerType ?? null,
        },
        ["loanEconomics"]: {
          ...tempLoanDetails["loanEconomics"],
          rehabLoanAmount:
            +loanData?.loanEconomics?.hardCosts +
              +loanData?.loanEconomics?.softCosts || null,
          totalRehabBudget:
            +loanData?.loanEconomics?.hardCosts +
              (+loanData?.loanEconomics?.softCosts ||
                +loanData?.loanEconomics?.softCostsCap15Rehab) || null,
        },
        ["loanInformation"]: {
          ...tempLoanDetails["loanInformation"],
          requiredInterestReserve:
            loanCharacterisation?.requiredInterestReservesAmount ?? null,
          requiredInterestReserveMonths:
            loanCharacterisation?.requiredInterestReservesMonth ?? null,
        },
        ["loanFeatures"]: {
          ...tempLoanDetails["loanFeatures"],
          originalAsIsLtv: getPercentageValue(
            loanResultData?.loanFeatures?.originalAsIsLtv,
            true
          ),
          originalAsRepairedLTV: getPercentageValue(
            loanResultData?.loanFeatures?.originalAsIsRepairedLtv,
            true
          ),
          originalLtc: getPercentageValue(
            loanResultData?.loanFeatures?.originalLtc,
            true
          ),
          asIsLTV: getPercentageValue(
            loanResultData?.loanFeatures?.originalAsIsLtv,
            true
          ),
          ltcAsIs: getPercentageValue(
            loanResultData?.loanFeatures?.originalLtc,
            true
          ),
          aRVLTVAsCompleted: getPercentageValue(
            loanResultData?.loanFeatures?.arvLtvAsCompleted,
            true
          ),
          blendedLTCAsCompleted: getPercentageValue(
            loanResultData?.loanFeatures?.blendedLtcAsCompleted,
            true
          ),
          blendedLTCwithFundedIRAsCompleted: getPercentageValue(
            loanResultData?.loanFeatures?.blendedLTCWithFundedIRAsCompleted,
            true
          ),
        },
        ["loanCharacterisation"]: {
          ...tempLoanDetails["loanCharacterisation"],
          customerTypeExperiencedNew:
            loanCharacterisation?.customerType ?? null,
          purchaseOrRefinance:
            loanCharacterisation?.purchaseOrRefinance ?? null,
          rehabType: loanCharacterisation?.rehabType ?? null,
          stabilizedFlag: loanCharacterisation?.stabilizedFlag ?? null,
          loanCharacterisation:
            loanCharacterisation?.loanCharacterisation ?? null,
          tier: loanCharacterisation?.tier ?? null,
          loanPurpose: loanCharacterisation?.loanPurpose ?? null,
          indexOfMostRestrictiveProperty:
            loanCharacterisation?.indexOfMostRestrictiveProperty ?? null,
        },
      };

      if (tempLoanTypeValue === "Ground Up") {
        tempLoanDetails = {
          ...tempLoanDetails,
          ["loanEconomics"]: {
            ...tempLoanDetails["loanEconomics"],
            totalBudget:
              +loanData?.loanEconomics?.hardCosts +
                +loanData?.loanEconomics?.softCosts ||
              +loanData?.loanEconomics?.hardCosts +
                +loanData?.loanEconomics?.softCostsCap15Rehab ||
              null,
          },
        };
      }
      Object.keys(tempPropertiesResult)?.forEach((key: string, idx: number) => {
        const property = tempPropertiesResult[idx];
        const propObj: ObjectType = {
          loanFeatures: {
            ...property?.loanFeatures,
            eSRIPopulationDensity:
              propertyKeys[idx]?.loanFeatures?.esriPopulationDensity,
            cbsa: propertyKeys[idx]?.loanFeatures?.cbsa ?? null,
            medianValue:
              (propertyKeys[idx]?.loanFeatures?.medianValue ||
                propertyKeys[idx]?.loanFeatures?.medianHomePriceValue) ??
              null,
            ltmHpa:
              propertyKeys[idx]?.loanFeatures?.ltmHpa ||
              propertyKeys[idx]?.loanFeatures?.medianHomeHpa
                ? getPercentageValue(
                    +propertyKeys[idx]?.loanFeatures?.ltmHpa ||
                      +propertyKeys[idx]?.loanFeatures?.medianHomeHpa,
                    true,
                    4
                  ) ?? null
                : null,
            daysOnMarket: propertyKeys[idx]?.loanFeatures?.daysOnMarket ?? null,
          },
          propertyEconomics: {
            ...property.propertyEconomics,
            costBasis:
              getCostBasisValue(property?.propertyEconomics, loanTypeData) ??
              null,
          },
        };
        if (
          (rehabTypes.includes(tempLoanTypeValue) &&
            loanTypeData?.propertyType === "Multifamily/MixedUse") ||
          (["Multifamily/MixedUse"].includes(loanTypeData?.propertyType) &&
            loanTypeData?.loanType === "Ground Up")
        ) {
          propObj["marketData"] = {
            ...property["marketData"],
            marketName: propertyKeys[idx]?.marketData?.marketName ?? null,
            capRate: propertyKeys[idx]?.marketData?.capRate
              ? propertyKeys[idx]?.marketData?.capRate * 100
              : null,
            twelveMonthRentalGrowth: propertyKeys[idx]?.marketData
              ?.twelveMonthRentalGrowth
              ? propertyKeys[idx]?.marketData?.twelveMonthRentalGrowth * 100
              : null,
            rentPerSqFt: propertyKeys[idx]?.marketData?.rentPerSqft ?? null,
            rentPerUnit: propertyKeys[idx]?.marketData?.rentPerUnit ?? null,
            salePriceGrowth: propertyKeys[idx]?.marketData?.salePerGrowth
              ? propertyKeys[idx]?.marketData?.salePerGrowth * 100
              : null,
            salePricePerUnit:
              propertyKeys[idx]?.marketData?.salePerPriceUnit ?? null,
            population: propertyKeys[idx]?.marketData?.population ?? null,
            vacancyRate: propertyKeys[idx]?.marketData?.vacancyRate
              ? propertyKeys[idx]?.marketData?.vacancyRate * 100
              : null,
          };
          propObj["subMarketData"] = {
            ...property["subMarketData"],
            subMarketName:
              propertyKeys[idx]?.subMarketData?.subMarketName ?? null,
            capRate: propertyKeys[idx]?.subMarketData?.capRate
              ? propertyKeys[idx]?.subMarketData?.capRate * 100
              : null,
            twelveMonthRentalGrowth: propertyKeys[idx]?.subMarketData
              ?.twelveMonthRentalGrowth
              ? propertyKeys[idx]?.subMarketData?.twelveMonthRentalGrowth * 100
              : null,
            rentPerSqFt: propertyKeys[idx]?.subMarketData?.rentPerSqft ?? null,
            rentPerUnit: propertyKeys[idx]?.subMarketData?.rentPerUnit ?? null,
            salePriceGrowth: propertyKeys[idx]?.subMarketData?.salePerGrowth
              ? propertyKeys[idx]?.subMarketData?.salePerGrowth * 100
              : null,
            salePricePerUnit:
              propertyKeys[idx]?.subMarketData?.salePerPriceUnit ?? null,
            population: propertyKeys[idx]?.subMarketData?.population ?? null,
            vacancyRate: propertyKeys[idx]?.subMarketData?.vacancyRate
              ? propertyKeys[idx]?.subMarketData?.vacancyRate * 100
              : null,
          };
        }
        propertyResult.push(propObj);
      });
      break;
    default:
      break;
  }
  return { tempLoanDetails, propertyResult };
};

export function getCookie(name: string) {
  return Cookies.get(name) === undefined ? null : Cookies.get(name);
}

export function setCookie(name: string, value: any, domain?: string) {
  const date = new Date();
  date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
  const secureFlag: any = window.location.hostname !== "localhost";
  let options: { expires?: Date; secure?: boolean; domain?: string } = {
    expires: date,
    secure: secureFlag,
  };
  options = domain ? { ...options, domain } : options;
  Cookies.set(name, value, options);
}

export function removeAllCookie() {
  const cookies = Cookies.get();
  for (const cookieName in cookies) {
    if (Object.prototype.hasOwnProperty.call(cookies, cookieName)) {
      Cookies.remove(cookieName);
    }
  }
}

export function evaluation(
  templateObject: string,
  request: ObjectType,
  templateLabels: any
) {
  // NOTE: Do not remove console statement as vite build is removing unused variable
  console.log("request", request?.loan?.loanStage);
  let firstEvaluation;
  try {
    if (templateObject) {
      firstEvaluation = eval(templateObject);
      if (firstEvaluation?.includes("undefined"))
        return templateLabels?.genericTemplateMessage;
      try {
        let secondEval = eval(firstEvaluation);
        secondEval = secondEval.replace(/"undefined"/g, "");
        secondEval = secondEval.replace(/undefined/g, "");
        return secondEval;
      } catch (error) {
        if (firstEvaluation?.includes("undefined"))
          return templateLabels?.genericTemplateMessage;
        firstEvaluation = firstEvaluation.replace(/"undefined"/g, "");
        firstEvaluation = firstEvaluation.replace(/undefined/g, "");
        return firstEvaluation;
      }
    } else {
      return templateLabels?.genericTemplateMessage;
    }
  } catch (error) {
    return templateLabels?.genericTemplateMessage;
  }
}

export function evalTestNames(representableTestName: string) {
  try {
    return eval(representableTestName);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getOverAllResults = (failingTests: any) => {
  let result = "FAIL";

  const failingTest = failingTests.filter((test: any) => {
    return test.overAllResults === "FAIL";
  });
  failingTest.length > 0 ? (result = "FAIL") : (result = "PASS");
  return result;
};

export const evaluationTestFieldTypes: { [key: string]: any } = {
  guidelineTest: {
    profitabilityTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Profitability Test",
    },
    rentYieldTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Rent Yield Test",
    },
  },
  loanEconomics: {
    annualizedIOPayments: {
      fieldType: "currency",
      readableName: "Annualized IO Payments",
    },
    dscrCalculation: {
      fieldType: "currency", // changed
      readableName: "DSCR Calculation",
    },
    profitabilityCalculation: {
      fieldType: "percentage",
      readableName: "Profitability Calculation",
    },
    profitabilityTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Profitability Test",
    },
    rentYieldCalculation: {
      fieldType: "currency", // changed
      readableName: "Rent Yield Calculation",
    },
    rentYieldTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Rent Yield Test",
    },
    dscrRequirementTest: {
      isTest: true,
      fieldType: "string",
      readableName: "DSCR Requirement Test",
    },
    monthlyInterest: {
      fieldType: "currency",
    },
    monthlyPrincipalDuringAmortization: {
      fieldType: "currency",
    },
    monthlyQualifyingPI: {
      fieldType: "currency",
    },
    monthlyQualifyingPayment: {
      fieldType: "currency",
    },
    // prePaymentPenaltyMonths not in sheet for dscr
  },
  guideLineLimit: {
    maxTerm13to18Months: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Term 13 to 18 Months",
    },
    minPerUnit100kMSA: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Per Unit (100k MSA)",
    },
    minPerUnit150kMSA: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Per Unit (150k MSA)",
    },
    budget150percentageAsIsPurchasePrice: {
      fieldType: "string",
      isTest: true,
      readableName: "Budget 150% Age As Is Purchase Price",
    },
    interestReserves: {
      fieldType: "string",
      isTest: true,
      readableName: "Interest Reserves",
    },
    maxLeverageArvLtv: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Leverage ArvLtv",
    },
    maxLeverageAsIsLtv: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Leverage As Is Ltv",
    },
    maxLeverageLtc: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Leverage Ltc",
    },
    maxLoanSize: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Loan Size",
    },
    maxTerm0To13Months: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Term 0 To 13 Months",
    },
    maxTerm18To24Months: {
      fieldType: "string",
      isTest: true,
      readableName: "Maximum Term > 18 Months",
    },
    minLoanSize: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Loan Size",
    },
    minMedianFico: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Median Fico",
    },
    minPerUnit175kMSA: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Per Unit 175kMSA",
    },
    minTerm12Months: {
      fieldType: "string",
      isTest: true,
      readableName: "Minimum Term 12Months",
    },
    msapropertyLocationHighValue150KPart1: {
      fieldType: "string",
      readableName: "MSA Property Location High Value 150K Part1",
    },
    msapropertyLocationHighValue150KPart2: {
      isTest: true,
      fieldType: "string",
      readableName: "MSA Property Location High Value 150K Part2",
    },
    msapropertyLocationHighValue175KPart1: {
      fieldType: "string",
      readableName: "MSA Property Location High Value 175K Part1",
    },
    msapropertyLocationHighValue175KPart2: {
      isTest: true,
      fieldType: "string",
      readableName: "MSA Property Location High Value 175K Part2",
    },
    msapropertyLocationHighValuePart1: {
      fieldType: "string",
      readableName: "MSA Property Location High Value Part1",
    },
    msapropertyLocationHighValuePart2: {
      isTest: true,
      fieldType: "string",
      readableName: "MSA Property Location High Value Part2",
    },
    nonStabilizedPerUnit: {
      isTest: true,
      fieldType: "string",
      readableName: "Non Stabilized Per Unit",
    },
    sfrNotStabilisedMinValue: {
      isTest: true,
      fieldType: "string",
      readableName: "Sfr Not Stabilised Min Value",
    },
    stabilizedPerUnit: {
      isTest: true,
      fieldType: "string",
      readableName: "Stabilized Per Unit",
    },
  },
  logicTest: {
    agedCreditReport: {
      isTest: true,
      fieldType: "string",
      readableName: "Aged Credit Report",
    },
    asIsOrCostGreaterThanArv: {
      isTest: true,
      fieldType: "string",
      readableName: "As Is Or Cost Greater Than Arv",
    },
    purchasegreaterThan120PercentOfAsIs: {
      isTest: true,
      fieldType: "string",
      readableName: "Purchase Greater Than 120% Of As Is",
    },
    recourseTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Recourse Test",
    },
  },
  multiFamilyMixedUse: {
    loanLimitforApproval: {
      fieldType: "string",
      readableName: "Loan Limit for Approval",
    },
    actualDebtYield: {
      fieldType: "percentage",
      readableName: "Actual Debt Yield",
    },
    appraisalNoiorNof: {
      fieldType: "currency", // changed
      readableName: "Appraisal Noi or Nof",
    },
    debtYeildTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Debt Yeild Test",
    },
    minDebtYeildRequirement: {
      fieldType: "string", // number field
      readableName: "Min Debt Yeild Requirement",
    },
    minLiquidityRequirement: {
      fieldType: "string",
      readableName: "Minimum Liquidity Requirement",
    },
    operatingExpenseandVacancy: {
      fieldType: "string", // number field
      readableName: "Operating Expense and Vacancy",
    },
    toorakNoiorNof: {
      fieldType: "currency",
      readableName: "Toorak Noi or Nof",
    },
    underwrittenNoiorNof: {
      fieldType: "currency",
      readableName: "Underwritten Noi or Nof",
    },
    valuePerUnit: {
      fieldType: "currency",
      readableName: "Value Per Unit",
    },
  },
  bridgeRentalPropertyTest: {
    agedPropertyValuation: {
      isTest: true,
      fieldType: "string",
      readableName: "Aged Property Valuation",
    },
    desktopRequirement: {
      isTest: true,
      fieldType: "string",
      readableName: "Desktop Requirement",
    },
    feasibilityReportRequired: {
      fieldType: "string",
      readableName: "Feasibility Report Required",
    },
    homePriceDecline: {
      isTest: true,
      fieldType: "string",
      readableName: "Home Price Decline",
    },
    minimumPropertyValueTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Minimum Property Value Test",
    },
    minSquareFootage: {
      isTest: true,
      fieldType: "string",
      readableName: "Minimum Square Footage",
    },
    propertyLevelTest: {
      isTest: true,
      fieldType: "string",
      readableName: "Property Level Test",
    },
    significantExpansion: {
      fieldType: "string",
      readableName: "Significant Expansion",
    },
    loanStructure: {
      isTest: true,
      fieldType: "string",
      readableName: "Loan Structure",
    },
  },
  maxLeverageTest: {
    maxLTCOne: {
      fieldType: "percentage",
      readableName: "Maximum LTC One",
    },
    maxLTVARV: {
      fieldType: "percentage",
      readableName: "Maximum LTV ARV",
    },
    maxLTVAsIs: {
      fieldType: "percentage",
      readableName: "Maximum LTV As Is",
    },
  },
};

export const guidlineTestsMap: any = {
  medianHomeValueMultiplier: "Median Home Value Multiplier",
  noEntitlements: "No Entitlements",
  experienceCompletedGUCProjects: "Experience",
  minValueForCondoTh100KUnit: "Min Value For 2-4 Unit Condo/TH $100,000/Unit",
  minValueForSF150KHome: "Min Value For SF $150,000/Home or 2-4 Houses /Home",
};

export function getFailedTestName(evaluationResults: any, testType: string) {
  const failedTestNames: any[] = [];
  const validKeys =
    evaluationResults &&
    Object.keys(evaluationResults).filter(
      (key) =>
        typeof evaluationResults[key] === "object" && evaluationResults[key]
    );

  if (validKeys) {
    validKeys.forEach((key: any) => {
      const object = evaluationResults[key];
      Object.entries(object).forEach((entry) => {
        if (key === "warehouseLine") {
          failedTestNames.push(
            `loan.${testType}.${key}.${entry[0]}.message.FAIL`
          );
        }

        if (
          typeof entry[1] === "string" &&
          (entry[1].toLowerCase() === "fail" ||
            entry[1].toLowerCase() === "waived") &&
          entry[0].toLowerCase() !== "sectioneligibility" &&
          entry[0].toLowerCase() !== "propertyleveltest"
        ) {
          failedTestNames.push(`${testType}.${key}.${entry[0]}.message.FAIL`);
        }
        failedTestNames.push(`testNames.${entry[0]}`);
      });
      failedTestNames.push(`testNames.${key}`);
    });
  }
  return failedTestNames;
}

export const isNullOrUndefined = (value: any) => {
  if (value === null || value === undefined) {
    return true;
  }
  return false;
};

export function isEmptyValue(inputValue: any) {
  let value = inputValue;
  if (typeof value === "string") value = value.trim();
  return value === "" || isNullOrUndefined(value);
}

export function getNumFromCommaSeparatedNum(value: any) {
  return typeof value === "string"
    ? Number(value.replace(/,/g, ""))
    : Number(value);
}

export const currencyToNumber = (value: string) => {
  try {
    return value?.replace("$", "")?.replace(/,/g, "");
  } catch (error) {
    console.error(error);
    return value;
  }
};

export function sanitizePercentage(
  text: any,
  multiplyBy100?: boolean,
  deci: number = 4
) {
  if (text || text === 0) {
    let textToChange = text;
    let result = textToChange;
    if (typeof result === "string" && result?.includes("%"))
      result = result.replace("%", "");
    if (multiplyBy100) {
      const percentage = typeof text === "number" ? text : Number(text);
      textToChange = isNaN(percentage)
        ? `${text}`
        : `${(percentage * 100).toFixed(deci)}`;
      result = textToChange.replace(/\.$/, "").replace(/%/, "");
    } else {
      const temp = Number(result);
      if (!isNaN(temp)) result = temp.toFixed(deci);
    }
    return `${result}%`;
  }
  return text;
}

export function isValidPercentage(text: string, blockNegative?: boolean) {
  let textToCheck = text;
  if (text && text.search("-") >= 0) {
    if (blockNegative) {
      return false;
    }
    textToCheck = text.replace("-", "");
  }
  return /^\d+(\.\d+)?%{0,1}$/.test(textToCheck);
}

export const isValidCurrency = (text: string) => {
  return /^\${0,1}[0-9]\d*(((,\d{3}){1})*(\.\d{1,2})?)$/.test(text);
};

export function MMMDDYYYY(date: any) {
  try {
    if (!date) return "";
    let nDate = date;
    if (typeof nDate === "number") {
      nDate = moment(new Date(nDate));
    } else if (typeof nDate === "string") {
      nDate = String(nDate).split("T")[0];
    }
    return moment.utc(nDate).format("MMM DD, YYYY");
  } catch (err) {
    return "";
  }
}

export function formatValueByType(value: any, fieldType?: string) {
  try {
    switch (fieldType) {
      case "string":
        return isEmptyValue(value) ? value : `${value}`;
      case "percentage":
      case "percentageWithMinus": {
        const formattedValue = sanitizePercentage(value, true);
        return isValidPercentage(formattedValue) ? formattedValue : value;
      }
      case "percentageTwoDecimal": {
        const formattedValue = sanitizePercentage(value, true, 2);
        return isValidPercentage(formattedValue) ? formattedValue : value;
      }
      case "percentageToFloat": {
        try {
          const temp = value.replace("%", "");
          if (isNaN(temp)) {
            return temp;
          }
          return Number.parseFloat(temp) / 100;
        } catch (e) {
          console.error(e, value, fieldType);
          return value;
        }
      }
      case "percentageWithout100x": {
        const formattedValue = sanitizePercentage(value, false);
        return isValidPercentage(formattedValue) ? formattedValue : value;
      }
      case "currency":
      case "currencyWithMinus": {
        const formattedValue = sanitizeCurrency(value);
        return isValidCurrency(formattedValue)
          ? Number(value) < 0
            ? formattedValue.replace("$", "$ -")
            : formattedValue
          : value;
      }
      case "decimal": {
        const formattedValue = sanitizeCurrency(value);
        return formattedValue !== "$NaN"
          ? formattedValue.replace(/,/g, "").replace("$", "")
          : value;
      }
      case "number": {
        if (isEmptyValue(value)) {
          return value;
        }
        const formattedValue = Number(value);
        if (isNaN(formattedValue)) {
          return value;
        }
        return formattedValue;
      }
      case "MMMDDYYYYDate": {
        return MMMDDYYYY(value);
      }
      case "decimalFixedto3": {
        return Number.parseFloat(value).toFixed(3);
      }
      case "decimalFixedto4": {
        return Number.parseFloat(value).toFixed(4);
      }
      case "checkbox": {
        return value?.toString() ?? "true";
      }
      default:
        return value;
    }
  } catch (error) {
    console.error(error, value);
    return value;
  }
}

export const totalRehabBudgeFormula = (
  data: any,
  dontDoConversion?: boolean
) => {
  try {
    const { loanDetails } = data;
    const hardCost = currencyToNumber(
      loanDetails?.fesLoanEconomics?.hardCost ?? ""
    );
    const softCost = currencyToNumber(
      loanDetails?.fesLoanEconomics?.softCost ?? ""
    );
    if (!isEmptyValue(hardCost) && !isEmptyValue(softCost)) {
      const value = Number(hardCost) + Number(softCost);
      return dontDoConversion
        ? value
        : formatValueByType(value.toString(), "currency");
    }
  } catch (error) {
    console.error(error);
  }
  return null;
};

export function yesornToBoolean(value: any) {
  if (value === "Yes" || value === "Y") {
    return true;
  }
  if (value === "No" || value === "N") {
    return false;
  }
  return null;
}

export function getLoanRequestObject(
  createLoanParams: any,
  loanId: any,
  loanType: string,
  condoEligibSave?: boolean,
  calculatedCondo?: string
) {
  const originatorInfo = JSON.parse(getCookie("originatorData") || "null");

  const loanInformation = createLoanParams?.loanInformation || {};
  const {
    loanStructure,
    loanPurpose,
    cashoutFlag,
    recourse,
    originationDate,
    expectedClosingDate,
    borrowerProceeds,
    cashOutRefinance,
    numberOfProperties,
    predominantState,
    pledgeOfEquity,
    predominantPropertyType,
    condoEligibility,
    loanTerm,
    calculatedCondoEligibility,
    exitStrategy,
    isPermitApprovalsAndFullPlansInPlace,
    irFundingSource,
    sizerRateLockPeriod,
    primaryLoanId,
    crossCollaterlization,
    creditEvent,
  } = loanInformation;

  const loanEconomics = createLoanParams?.loanEconomics || {};
  const {
    rehabBudget,
    existDebt,
    softCost,
    hardCost,
    originalLoanAmount,
    totalOriginationAndDiscountPoints,
    interestRate,
    prepaymentPenalty,
    prepayPenaltyType,
    rateType,
    interestOnlyPeriod,
    interestReserve,
    includeOutOfPocketBudgetInARLTV,
  } = loanEconomics;

  const borrowerInformation = createLoanParams?.borrowerInformation || {};
  const {
    propertiesCompletedWithinLast36Months,
    borrowerGuarantor,
    borrowerType,
    creditScoreActual,
    borrowerGUCExperience,
    heavyRehabExperience,
    inState,
  } = borrowerInformation;

  const loanCondo = createLoanParams?.loanCondo || {};
  const loanDetailId = createLoanParams?.loanDetailId || {};
  const dueDiligencePartyInfo = createLoanParams?.dueDiligencePartyInfo || {};
  const loanSizerType = createLoanParams?.loanSizerType || "";
  const loanConfig = createLoanParams?.loanConfig || {};

  const partyType = borrowerType === "Entity" ? "account" : "person";
  const accountType = borrowerType === "Entity" ? "BORROWER" : "person";
  const borrowerInfo: any[] = [];
  borrowerInfo.push({
    loanUserType: "Borrower",
    loanUserSequence: "1",
    isPrimary: borrowerType !== "Entity",
    originalCreditScoreMedian: isEmptyValue(creditScoreActual)
      ? null
      : getNumFromCommaSeparatedNum(creditScoreActual).toString(),
    originalCreditScoreReportDate: null,
    experience: isEmptyValue(propertiesCompletedWithinLast36Months)
      ? null
      : getNumFromCommaSeparatedNum(propertiesCompletedWithinLast36Months),
    inState: isEmptyValue(inState) ? null : inState,
    borrowerGUCExperience: isEmptyValue(borrowerGUCExperience)
      ? null
      : getNumFromCommaSeparatedNum(borrowerGUCExperience),
    heavyRehabExperience: isEmptyValue(heavyRehabExperience)
      ? null
      : getNumFromCommaSeparatedNum(heavyRehabExperience),
    ownershipOfEntity: null,
    customer: {
      accountName: "",
      accountType,
      addressList: [],
      contactList: [],
      dateOfBirth: null,
      firstName: "",
      middleName: null,
      lastName: null,
      partyType,
      foreignNationalString: borrowerGuarantor,
    },
  });
  const loanUserMap = [...borrowerInfo];

  // Boolean value only for loan request  body
  let selectedCrossValue;
  if (crossCollaterlization === "Y") {
    selectedCrossValue = true;
  } else if (crossCollaterlization === "N") {
    selectedCrossValue = false;
  }

  const rehabBudgetValue =
    loanSizerType === LoanSizerEnum.multifamilyMixedUse
      ? totalRehabBudgeFormula({ loanDetails: createLoanParams })
      : rehabBudget;
  const loanRequestObject = {
    loanId: loanId.toString(),
    loanTypeId: 2,
    loanSizerType,
    loanType,
    loanExcelJson: "",
    // loanState: "in-progress",
    loanEconomics: {
      totalBudgetAmount: rehabBudgetValue
        ? rehabBudgetValue?.replace("$", "")
        : rehabBudget?.replace("$", "") || 0,
      originalLoanAmount: originalLoanAmount?.replace("$", "") || null,
      rehabAmount:
        totalRehabBudgeFormula({ loanDetails: createLoanParams })?.replace(
          "$",
          ""
        ) || null,
      existDebt: existDebt?.replace("$", "") || null,
      softCost: softCost?.replace("$", "") || null,
      hardCost: hardCost?.replace("$", "") || null,
      // originalLoanAmount:borrowerRquested,
      interestRateAsOfCutOffDate: interestRate
        ? Number(interestRate?.replace("%", ""))
        : null,
      prepaymentPenaltyMonths: prepaymentPenalty ?? null,
      prepayPenaltyType,
      rateType,
      interestOnlyPeriod: !isEmptyValue(interestOnlyPeriod)
        ? parseInt(interestOnlyPeriod, 10)
        : null,
      totalOriginationAndDiscountPoints: totalOriginationAndDiscountPoints
        ? totalOriginationAndDiscountPoints?.replace("%", "")
        : null,
      interestReserve: interestReserve?.replace("$", "").replace(/,/g, ""),
      includeOutOfPocketBudgetInARLTV: includeOutOfPocketBudgetInARLTV ?? true,
    },
    loanInfo: {
      loanStructure: loanStructure || null,
      loanPurpose: loanPurpose || null,
      cashoutFlag: cashoutFlag || null,
      recourseString: recourse,
      pledgeOfEquity: yesornToBoolean(pledgeOfEquity),
      isPermitApprovalsAndFullPlansInPlace,
      expectedClosingDate,
      irFundingSource,
      sizerRateLockPeriod,
      borrowerProceeds: borrowerProceeds || cashOutRefinance || null,
      noOfProperties: Number(numberOfProperties) || null,
      predominantState,
      predominantPropertyType,
      condoEligibility,
      originationDate,
      loanTermMonthly: Number(loanTerm) || null,
      calculatedCondoEligibility,
      exitStrategy: isEmptyValue(exitStrategy) ? null : exitStrategy,
      primaryLoanId,
      // takeoutPartnerId,
      crossCollaterlization: selectedCrossValue,
      creditEvent,
    },
    loanUserMap,

    loanConfig: loanConfig ? loanConfig : {},
    loanCondominium: {},
    loanDetailId,
    dueDiligencePartyInfo,
    originatorInfo,
  };

  if (condoEligibSave === true) {
    loanRequestObject.loanInfo.calculatedCondoEligibility =
      calculatedCondo || calculatedCondoEligibility;
    loanRequestObject.loanInfo.condoEligibility =
      calculatedCondo || condoEligibility;
    loanRequestObject.loanCondominium = {
      borrowerName: loanCondo?.borrowerName,
      monthlyHOAFee:
        loanCondo?.monthlyHOAFee !== null
          ? loanCondo?.monthlyHOAFee?.replace("$", "")
          : null,
      hoaDuesAboveFifteen:
        loanCondo?.hoaDuesAboveFifteen !== null
          ? loanCondo?.hoaDuesAboveFifteen === "Yes"
          : null,
      hoaDuesAboveTwenty:
        loanCondo?.hoaDuesAboveTwenty !== null
          ? loanCondo?.hoaDuesAboveTwenty === "Yes"
          : null,
      reserveFundsForOneYear:
        loanCondo?.reserveFundsForOneYear !== null
          ? loanCondo?.reserveFundsForOneYear === "Yes"
          : null,
      reserveFundsForTwoYears:
        loanCondo?.reserveFundsForTwoYears !== null
          ? loanCondo?.reserveFundsForTwoYears === "Yes"
          : null,
      isProjectCompleted:
        loanCondo?.isProjectCompleted !== null
          ? loanCondo?.isProjectCompleted === "Yes"
          : null,
      subjectToAdditionalPhasing:
        loanCondo?.subjectToAdditionalPhasing !== null
          ? loanCondo?.subjectToAdditionalPhasing === "Yes"
          : null,
      soldPercentage:
        loanCondo?.soldPercentage !== null
          ? loanCondo?.soldPercentage?.replace("%", "") / 100 || null
          : null,
      hoaUnderOwnerControl:
        loanCondo?.hoaUnderOwnerControl !== null
          ? loanCondo?.hoaUnderOwnerControl === "Yes"
          : null,
      simpleEstateOwnership:
        loanCondo?.simpleEstateOwnership !== null
          ? loanCondo?.simpleEstateOwnership === "Yes"
          : null,
      ownerOccupiedUnits: Number(loanCondo?.ownerOccupiedUnits) || null,
      renterOccupiedUnits: Number(loanCondo?.renterOccupiedUnits) || null,
      renterOccupiedPercentage:
        loanCondo?.renterOccupiedPercentage !== null
          ? loanCondo?.renterOccupiedPercentage?.replace("%", "") / 100 || null
          : null,
      individualOwnershipAboveTwentyFive:
        loanCondo?.individualOwnershipAboveTwentyFive !== null
          ? loanCondo?.individualOwnershipAboveTwentyFive === "Yes"
          : null,
      masterAssurancePolicy:
        loanCondo?.masterAssurancePolicy !== null
          ? loanCondo?.masterAssurancePolicy === "Yes"
          : null,
      projectHasMobileHomes:
        loanCondo?.projectHasMobileHomes !== null
          ? loanCondo?.projectHasMobileHomes === "Yes"
          : null,
      otherSourcesIncomeMoreThanTwenty:
        loanCondo?.otherSourcesIncomeMoreThanTwenty !== null
          ? loanCondo?.otherSourcesIncomeMoreThanTwenty === "Yes"
          : null,
      commercialPurposesAreaMoreThanForty:
        loanCondo?.commercialPurposesAreaMoreThanForty !== null
          ? loanCondo?.commercialPurposesAreaMoreThanForty === "Yes"
          : null,
      restrictOwnerToRent:
        loanCondo?.restrictOwnerToRent !== null
          ? loanCondo?.restrictOwnerToRent === "Yes"
          : null,
      documentsWithSEC:
        loanCondo?.documentsWithSEC !== null
          ? loanCondo?.documentsWithSEC === "Yes"
          : null,
      hoawithLitOrBankruptcy:
        loanCondo?.hoawithLitOrBankruptcy !== null
          ? loanCondo?.hoawithLitOrBankruptcy === "Yes"
          : null,
      ownerControlStartDate:
        loanCondo?.ownerControlStartDate !== ""
          ? loanCondo?.ownerControlStartDate
          : null,
      indivdualOwnedUnits: Number(loanCondo?.indivdualOwnedUnits),
      hoawithLitOrBankruptcyDesc: loanCondo?.hoawithLitOrBankruptcyDesc,
      restrictOwnerToRentOutDesc: loanCondo?.restrictOwnerToRentOutDesc,
      condoProjectName: loanCondo?.condoProjectName,
      streetAddressLine1: loanCondo?.streetAddressLine1,
      noOfUnits: Number(loanCondo?.noOfUnits),
      state: loanCondo?.state,
      city: loanCondo?.city,
      postalCode: loanCondo?.postalCode,
    };
  }
  return loanRequestObject;
}

export function downLoadCondo(
  createLoanParams: any,
  loanId: any,
  loanType: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    // const url = getURL("public-api/aggregate/loans/condo");
    const loanRequestObject = getLoanRequestObject(
      createLoanParams,
      loanId,
      loanType,
      true
    );
    const requestObject = loanRequestObject;

    postDownloadCondo(requestObject)
      .then((res: { data: any }) => resolve(res.data))
      .catch((e: any) => {
        reject(e);
      });
  });
}

export function exportHTML({
  loanDetails,
  loanId,
  loanType,
}: {
  loanDetails: any;
  loanId: any;
  loanType: any;
}) {
  downLoadCondo(loanDetails, loanId, loanType).then((response) => {
    const source = response;
    const fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = "condoEligibility.doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  });
}

const largestDataPoint = "300px";

export const getEligiblePartnersCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType[] = [
    {
      id: "empty",
      title: "",
      headStyle: {
        padding: "0",
        fontWeight: "600",
        borderRight: "1px solid #dedede",
        minWidth: "300px",
        maxWidth: "300px",
      },
    },
  ];
  const sortedPartnerList: any = partnerList?.sort(
    (partner1: any, partner2: any) =>
      partner1.criticalFailed - partner2.criticalFailed
  );
  // eslint-disable-next-line no-unused-expressions
  sortedPartnerList?.forEach((partnerData: ObjectType, index: number) => {
    const partnerId = partnerData.key || partnerData.id;
    cols.push({
      id: partnerId,
      title: partnerData.programName,
      failedCount: partnerData.failedCount,
      criticalFailed: partnerData.criticalFailed,
      // maxWidth: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      padding: 0,
      "data-testid": `partner-pricing-data-${partnerData.label}`,
      headStyle: {
        // padding: "1rem",
        fontWeight: "600",
        textAlign: "center",
        borderRight: "1px solid #dedede",
      },
    });
  });
  return cols;
};

export const getfinalPricingCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType = [
    {
      id: "label",
      title: "Final Toorak Pricing",
      "data-testid": "final-toorak-pricing",
      // maxWidth: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      width: `${largestDataPoint}`,
      headStyle: {
        // padding: "1rem",
        fontWeight: "600",
      },
      style: {
        // padding: "1rem",
        borderRight: "1px solid #dedede",
      },
    },
  ];

  partnerList.forEach((partnerData: ObjectType, index: number) => {
    const partnerId = partnerData.key || partnerData.id;
    // if (index <= 0) {
    cols.push({
      id: partnerId,
      "data-testid": `final-toorak-pricing-${partnerId}`,
      title: "",
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      // maxWidth: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      // minWidth: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      headStyle: {
        // padding: "1rem",
        fontWeight: "600",
      },
      style: {
        // padding: "1rem",
        borderRight: "1px solid #dedede",
        textAlign: "center",
      },
    });
    // }
  });
  return cols;
};

export const getPricingAdjustmentsCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType = [
    {
      id: "label",
      title: "Pricing Adjustments",
      "data-testid": "pricing-adjustment",
      width: `${largestDataPoint}`,
      // maxWidth: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      headStyle: {
        // padding: "1rem",
        fontWeight: "600",
      },
      style: {
        // padding: "1rem",
        borderRight: "1px solid #dedede",
      },
    },
  ];

  partnerList.forEach((partnerData: ObjectType, i: number) => {
    const partnerId = partnerData.key || partnerData.id;
    // if (i <= 0) {
    cols.push({
      id: partnerId,
      title: "",
      "data-testid": `pricing-adjustment-${partnerId}`,
      // minWidth: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      // maxWidth: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      headStyle: {
        // padding: "1rem",
        fontWeight: "600",
      },
      style: {
        // padding: "1rem",
        borderRight: "1px solid #dedede",
        textAlign: "center",
      },
    });
    // }
  });
  return cols;
};

const getRateLockExpiryDate = (
  rateLockExpiryDate: number,
  lockInPeriod: number,
  extendByDays: number = 0
) => {
  const expiry = moment
    .utc(rateLockExpiryDate)
    .add(parseInt(`${extendByDays}`, 10), "days");
  const expiryDate = `${expiry.format("ddd, MMM DD, YYYY")}`;

  return `${expiryDate} | ${lockInPeriod} Days`;
};

export const getRowsData = (
  fields: ObjectType[],
  partnersList: ObjectType[]
) => {
  return fields
    .filter(
      (fieldVal: ObjectType) =>
        (fieldVal?.hideCondition && fieldVal?.hideCondition()) ||
        !fieldVal?.hideCondition
    )
    .map((fieldData: ObjectType) => {
      let rowData: ObjectType = {
        label: fieldData.label,
      };
      partnersList?.forEach((partnerData: ObjectType) => {
        const partnerId = partnerData.key || partnerData.id;
        rowData = {
          ...rowData,
          [partnerId]:
            (fieldData.label !== "Pre/Post Close Rate Lock Expiry"
              ? partnerData.data?.[fieldData.field]
              : partnerData.data?.[fieldData.field.split(",")[0]] &&
                getRateLockExpiryDate(
                  partnerData.data?.[fieldData.field.split(",")[0]],
                  Number.parseInt(
                    partnerData.data?.[fieldData.field.split(",")[1]] || 0,
                    10
                  )
                )) ?? "--",
        };
      });
      return rowData;
    });
};

export const getLoanPricingFields = (
  version: string,
  additionalData?: ObjectType
) => {
  const versionToFieldsmap: ObjectType = {
    RATE: [
      {
        label: "Toorak Interest Rate",
        field: "toorakInterestRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.toorakInterestRate`,
        hideCondition: (data: any) => !data?.loanPricing?.toorakInterestRate,
      },
      {
        label: "Base Rate",
        field: "baseRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.baseRate`,
        hideCondition: (data: any) => !data?.loanPricing?.baseRate,
      },
      {
        label: "Base Price",
        field: "basePrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.basePrice`,
        hideCondition: (data: any) => !data?.loanPricing?.basePrice,
      },
      {
        label: "Actual Interest Rate",
        field: "actualInterestRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        overRideinfoContent: `data.loan.loanEconomics.interestRateAsOfCutOffDate`,
      },
      {
        label: "Variance From TRK Int. Rate",
        field: "interestRateVariance",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.interestRateVariance`,
        hideCondition: (data: any) => !data?.loanPricing?.interestRateVariance,
      },
      {
        label: "Price Adjustment",
        field: "priceAdjustment",
        fieldType: "",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.priceAdjustment`,
        hideCondition: (data: any) => !data?.loanPricing?.priceAdjustment,
      },
      {
        label: "Prepayment Penalty - Max Price",
        field: "pppMaxPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.pppMaxPrice`,
        hideCondition: (data: any) => !data?.loanPricing?.pppMaxPrice,
      },
      {
        label: "State PPP Restriction - Max Price",
        field: "statepppRestrictionMaxPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.statepppRestrictionMaxPrice`,
        hideCondition: (data: any) =>
          !data?.loanPricing?.statepppRestrictionMaxPrice,
      },
      {
        label: "Final Toorak Price",
        field: "finalToorakPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.finalToorakPrice`,
        hideCondition: (data: any) => !data?.loanPricing?.finalToorakPrice,
      },
      {
        label: "Morgan Stanley Lease Status",
        field: "leaseStatus",
        fieldType: "dropdown",
        loanStage: [LoanStage.pre, LoanStage.post],
        infoContent: `loanPricing.leaseStatus`,
        dropDownValues: additionalData?.msLeaseStatusDropdownValue,
        hideCondition: (data: any) =>
          !data?.loanPricing?.leaseStatus &&
          !!additionalData?.msLeaseStatusDropdownValue,
      },
    ],
    "": [
      {
        label: "Final Toorak Price",
        field: "finalToorakPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.finalToorakPrice`,
        hideCondition: (data: any) => !data?.loanPricing?.finalToorakPrice,
      },
      {
        label: "Price Adj. - Total",
        field: "totalPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.totalPriceAdjustment`,
      },
      {
        label: "Base Price",
        field: "basePrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.basePrice`,
        hideCondition: (data: any) => !data?.loanPricing?.basePrice,
      },
      {
        label: "Prepayment Penalty - Max Price",
        field: "pppMaxPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.pppMaxPrice`,
        hideCondition: (data: any) => !data?.loanPricing?.pppMaxPrice,
      },
      {
        label: "State PPP Restriction - Max Price",
        field: "statepppRestrictionMaxPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.statepppRestrictionMaxPrice`,
      },
      {
        label: "Max Toorak Price",
        field: "maxAllowedToorakPrice",
        fieldType: "numeric",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.maxAllowedToorakPrice`,
        hideCondition: (data: any) =>
          data?.takeoutPartnerId === "c096ff03-b4c3-4691-bad9-aa448c328e7c" ||
          !data,
      },
      {
        label: "Morgan Stanley Lease Status",
        field: "leaseStatus",
        fieldType: "dropdown",
        loanStage: [LoanStage.pre, LoanStage.post],
        infoContent: `loanPricing.leaseStatus`,
        dropDownValues: additionalData?.msLeaseStatusDropdownValue,
        hideCondition: (data: any) =>
          !data?.loanPricing?.leaseStatus &&
          !!additionalData?.msLeaseStatusDropdownValue,
      },
    ],
  };
  return versionToFieldsmap?.[version] ?? versionToFieldsmap[""];
};

export const labelFieldName30year = (version: string) => {
  const versionToFieldsMap: ObjectType = {
    RATE: [
      {
        label: "Borrower FICO Pricing Bucket",
        field: "borrowerFico",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.borrowerFico`,
      },
      {
        label: "Actual Leverage - LTV/LTC/LTP",
        field: "actualLeverage",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.actualLeverage`,
      },
      {
        label: "Base FICO / Leverage Adjusted Rate",
        field: "baseFicoLeverageBucket",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.baseFicoLeverageBucket`,
      },
      {
        label: "Rate Adj. - Prepayment Penalty",
        field: "prePaymentPenalty",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.prePaymentPenalty`,
      },
      {
        label: "Rate Adj. - Interest Only",
        field: "interestOnlyRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.interestOnlyRate`,
      },
      {
        label: "Rate Adj. - DSCR",
        field: "dscrRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.dscrRate`,
      },
      {
        label: "Rate Adj. - Short Term Rental",
        field: "shortTermRentalRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.shortTermRentalRate`,
      },
      {
        label: "Rate Adj. - Cash Out Refinance",
        field: "cashOutRefinanceRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.cashOutRefinanceRate`,
      },
      {
        label: "Rate Adj. - Property Type",
        field: "propertyTypeRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.propertyTypeRate`,
      },
      {
        label: "Rate Adj. - Loan Balance",
        field: "loanBalanceRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.loanBalanceRate`,
      },
      {
        label: "Rate Adj. - ARM",
        field: "amortizationRate",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.amortizationRate`,
      },
      {
        label: "Leverage Pricing Bucket",
        field: "leveragePricingBucket",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.leveragePricingBucket`,
      },
      {
        label: "Loan Balance Bucket",
        field: "loanBalanceBucket",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanBalanceBucket`,
      },
      // {
      //   label: "After Adjustment Min Proceeds",
      //   field: "afterAdjustmentMinProceeds",
      //   fieldType: "currency",
      //
      //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
      //   infoContent: `toorakProceeds.afterAdjustmentMinProceeds`
      // },
      // {
      //   label: "Originator Proceeds",
      //   field: "originatorProceeds",
      //   fieldType: "currency",
      //
      //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
      //   infoContent: `toorakProceeds.originatorProceeds`
      // },
      // {
      //   label: "After Adjustment Cost Proceeds",
      //   field: "afterAdjustmentCostProceeds",
      //   fieldType: "currency",
      //
      //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
      //   infoContent: `toorakProceeds.afterAdjustmentCostProceeds`
      // },

      // {
      //   label: "Balance Variance",
      //   field: "balanceVariance",
      //   fieldType: "currency",
      //
      //   loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
      //   infoContent: `toorakProceeds.balanceVariance`
      // },
    ],
    "": [
      {
        label: "Borrower FICO Pricing Bucket",
        field: "borrowerFico",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.borrowerFico`,
      },
      {
        label: "Actual Leverage - LTV/LTC/LTP",
        field: "actualLeverage",
        fieldType: "percentage",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `toorakProceeds.actualLeverage`,
      },
      {
        label: "Price Adj. - FICO",
        field: "ficoPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.ficoPriceAdjustment`,
      },
      {
        label: "Price Adj. - Pre-payment Penalty Term",
        field: "penaltyTermPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.penaltyTermPriceAdjustment`,
      },
      {
        label: "Price Adj. - Loan Exceptions",
        field: "loanExceptionsPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanExceptionsPriceAdjustment`,
      },
      {
        label: "Price Adj. - DSCR",
        field: "dscrPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.dscrPriceAdjustment`,
      },
      {
        label: "Price Adj. - Loan Term",
        field: "shortTermRentalRate",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.shortTermRentalRate`,
      },
      {
        label: "Price Adj. - Loan Purpose",
        field: "loanPurposePriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanPurposePriceAdjustment`,
      },
      {
        label: "Price Adj. - Property Type",
        field: "propertyTypePriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.propertyTypePriceAdjustment`,
      },
      {
        label: "Price Adj. - FN/NPRA",
        field: "loanUserTypePriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanUserTypePriceAdjustment`,
      },
      {
        label: "Price Adj. - Loan Balance",
        field: "loanBalancePriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanBalancePriceAdjustment`,
      },
      {
        label: "Price Adj. - Cross Collateralized",
        field: "crossCollaterizedAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.crossCollaterizedAdjustment`,
      },
      {
        label: "Price Adj. - Amortization",
        field: "amortizationPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.amortizationPriceAdjustment`,
      },
      {
        label: "Leverage Pricing Bucket",
        field: "leveragePricingBucket",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.leveragePricingBucket`,
      },
      {
        label: "Loan Balance Bucket",
        field: "loanBalanceBucket",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanBalanceBucket`,
      },
      {
        label: "Price Adj. - Tier state",
        field: "propertyLocationPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.loanBalanceBucket`,
      },
      {
        label: "FC/SS/DIL/BK7 36 - 47mo",
        field: "creditEventPriceAdjustment",
        fieldType: "string",
        loanStage: [LoanStage.pre, LoanStage.post, LoanStage.fes],
        infoContent: `loanPricing.creditEventPriceAdjustment`,
      },
    ],
  };
  return versionToFieldsMap?.[version] ?? versionToFieldsMap?.[""];
};

export const getOriginatorLabel = (label: any, creditLines: ObjectType[]) => {
  const creditLineObj = creditLines.find((cdl: any) => cdl.fullName === label);
  return creditLineObj?.programName ?? label;
};

export const sanitizeLoanDataForTermSheet = (value: any) => {
  const tempValue = `${value}`?.replace(/(\$|%|months)/gi, "");
  return !isNaN(Number(tempValue)) &&
    /^\d+(\.\d{1,4})?$/.test(tempValue.toString())
    ? Number(tempValue)?.toFixed(2)
    : tempValue;
};

export const newLoanTypes = {
  bridgeLoan: "Bridge Loan",
  dscrLoan: "30 Year Loan",
};

export function capitalizeFirstLetterOfEachWord(str: string) {
  return str
    .split(" ") // Split the string into an array of words
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter and concatenate with the rest of the word
    .join(" "); // Join the array back into a string
}

export const getLoanType = {
  0: {
    name: "Bridge Loan",
    displayValue: "BridgeLoan",
    loanTypeId: 2,
    loanCreationName: "Bridge Loan",
    settlementTabPath: "Bridge",
  },
  1: {
    name: "Investor DSCR",
    displayValue: "InvestorDSCR",
    loanTypeId: 1,
    loanCreationName: "30 Year Loan",
    settlementTabPath: "DSCR",
  },
  2: {
    name: "2 + 1 Loan",
    displayValue: "TwoPlusOneLoan",
    loanTypeId: 3,
    loanCreationName: "2 + 1 Loan",
  },
  3: {
    name: "Bridge Toorval Loan",
    displayValue: "ToovalBridge",
    loanTypeId: 4,
    loanCreationName: "Toorval Bridge Loan",
  },
};

// Format required Adress String
export const cleanedAdressString = (propertyLocation: any) => {
  if (!propertyLocation) {
    return "";
  }
  const pattern = new RegExp(
    `\\b(${propertyLocation?.postalCode}|${propertyLocation?.city}|${propertyLocation?.state})\\b`,
    "g"
  );
  // Remove matches from the address
  const cleanedAddress = propertyLocation?.address
    ?.replace(pattern, "")
    ?.trim()
    ?.replace(/\s+/g, " ");
  const address = propertyLocation?.address
    ? `${cleanedAddress}, ${propertyLocation?.city}, ${propertyLocation?.state} ${propertyLocation?.postalCode}`
    : propertyLocation?.postalCode
    ? `${propertyLocation?.city}, ${propertyLocation?.state} ${propertyLocation?.postalCode}`
    : "";
  return address;
};

export const calculateCurrentSelectedInterestRate = (
  currentRateData: any,
  waiverRequest: any,
  buyDownOptions: any
) => {
  const currentSelectedInterestRate = { ...currentRateData };
  const activeWaiverRequestStatus = waiverRequest?.data?.data?.find(
    (item: any) => item?.data?.isActive
  )?.data?.waiverStatus;

  const requestedWaiver = waiverRequest?.data?.data?.find(
    (item: any) =>
      item?.data?.waiverStatus === "Requested" && item?.data?.isActive
  );
  const approvedWaivers = waiverRequest?.data?.data?.filter(
    (item: any) => item?.data?.waiverStatus === "Approved"
  );
  const latestUpdatedRecord =
    approvedWaivers?.filter((waiver: any) => waiver?.updatedAt) // Filter out records without updatedAt
      ?.sort(
        (a: any, b: any) =>
          new Date(b?.updatedAt).getTime() - new Date(a?.updatedAt).getTime()
      )[0] ?? {};

  if (activeWaiverRequestStatus === "Rejected") {
    const tempCurrentRateData = buyDownOptions?.find(
      (item: any) =>
        item?.interestRate * 100 ===
        parseFloat(currentSelectedInterestRate?.interestRate?.replace("%", ""))
    );

    if (tempCurrentRateData) {
      currentRateData = {
        discountPoints:
          tempCurrentRateData.discountPointPercentage?.toFixed(4) || "0.0000",
        discountPointsWithDollar:
          tempCurrentRateData.discountPointAmount?.toFixed(2) || "0.00",
        interestRate: `${(tempCurrentRateData.interestRate * 100)?.toFixed(
          3
        )}%`,
      };
    }
  } else if (
    Object.keys(requestedWaiver ?? {}).length &&
    approvedWaivers?.length === 0
  ) {
    const currentDiscountPoints =
      parseFloat(currentRateData?.discountPoints) || 0;
    const latestDiscountPoints =
      parseFloat(requestedWaiver.data?.requestedData?.discountPoints) || 0;
    const discountPoints = Math.max(
      0,
      currentDiscountPoints - latestDiscountPoints
    ).toFixed(4);
    const discountPointsWithDollar = Math.max(
      0,
      parseFloat(
        currentRateData?.discountPointsWithDollar?.replace(/[^0-9.]/g, "")
      ) -
        parseFloat(
          requestedWaiver?.data?.requestedData?.discountPointsAmount?.replace(/[^0-9.]/g, "")
        )
    ).toFixed(2);

    currentRateData = {
      ...currentRateData,
      discountPoints,
      discountPointsWithDollar,
    };
  } else if (
    approvedWaivers?.length > 0 &&
    Object.keys(latestUpdatedRecord ?? {}).length
  ) {
    const latestData = latestUpdatedRecord.data?.finalData || {};
    const currentDiscountPoints =
      parseFloat(currentRateData?.discountPoints) || 0;
    const latestDiscountPoints = parseFloat(latestData.discountPoints) || 0;

    const discountPoints = Math.max(
      0,
      currentDiscountPoints - latestDiscountPoints
    ).toFixed(4);
    const discountPointsWithDollar = Math.max(
      0,
      parseFloat(
        currentRateData?.discountPointsWithDollar?.replace(/[^0-9.]/g, "")
      ) - parseFloat(latestData.discountPointsAmount?.replace(/[^0-9.]/g, ""))
    ).toFixed(2);

    currentRateData = {
      ...currentRateData,
      discountPoints,
      discountPointsWithDollar,
    };
  }
  return { ...currentRateData };
};
